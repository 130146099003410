import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetSupplierOrdersQuery, useUpdateSupplierOrdersMutation } from '../store/slice/apiSlice';
import { motion } from 'framer-motion';
import left from '../images/left.svg';
import check from '../images/check.svg';
import xmark from '../images/xmark.svg';
import emptybag from '../images/emptybag.svg';

function ManageWholesalePage() {
    const [status, setStatus] = useState('未付款');
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    const { data: wholesaleData } = useGetSupplierOrdersQuery({ order_status: status, page: currentPage, page_size:6 });

    useEffect(() => {
        console.log(wholesaleData);
    }, [wholesaleData]);

    useEffect(() => {
        if (wholesaleData && wholesaleData.total_pages) {
            setMaxPage(wholesaleData.total_pages || 1);
        } else {
            setMaxPage(1);
        }
        if (wholesaleData) {
            initializeQuantities(wholesaleData.data);
        }
        console.log(wholesaleData);
    }, [wholesaleData]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getPageNumbers = () => {
        let pages = [];
        let startPage, endPage;
    
        if (maxPage <= 4) {
            // 少於6頁時，直接顯示所有頁碼
            startPage = 1;
            endPage = maxPage;
        } else {
            // 超過6頁時，計算起始和結束頁碼
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= maxPage) {
                startPage = maxPage - 3;
                endPage = maxPage;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }
    
        // 生成頁碼範圍
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [status]);

    const [showDetail, setShowDetail] = useState(null);
    const [orderQuantities, setOrderQuantities] = useState({});
    const [updateWholesale] = useUpdateSupplierOrdersMutation();

    const initializeQuantities = (data) => {
        const initialQuantities = {};
        data.forEach(order => {
            initialQuantities[order.supplier_order_id] = order.quantity;
        });
        setOrderQuantities(initialQuantities);
    };

    const handleQuantityChange = (id, value) => {
        setOrderQuantities(prev => ({ ...prev, [id]: value }));
    };

    const handleCancel = async (order) => {
        try {
            if (wholesaleData) {
                const updatedQuantity = orderQuantities[order.supplier_order_id];
                const data = {
                    quantity: updatedQuantity,
                    status: '已取消'
                }
                const supplier_order_id = showDetail;
                const result = await updateWholesale({supplier_order_id, data}).unwrap();
                console.log(result);
                setShowDetail(null);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleChange = async (order) => {
        try {
            if (wholesaleData) {
                const updatedQuantity = orderQuantities[order.supplier_order_id];
                const data = {
                    quantity: updatedQuantity,
                    status
                }
                const supplier_order_id = showDetail;
                const result = await updateWholesale({supplier_order_id, data}).unwrap();
                console.log(result);
                setShowDetail(null);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="bg-[#f5f5f5] w-full max-h-max min-h-screen flex flex-col items-center">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/seller'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">後台管理</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='flex w-full border-b-2 border-[#eeeeee] bg-white'>
                <Link to='/managecampaign' className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-[#858585]'>團購管理</div>
                </Link>
                <Link to='/manageorder' className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-[#858585]'>訂單管理</div>
                </Link>
                <div className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-main-brown'>批貨管理</div>
                    <div className='w-[40%] h-[5px] top-radious bg-main-brown flex'></div>
                </div>
            </div>
            <div className='flex flex-col w-full h-full items-center justify-between'>
                <div className='flex flex-col min-h-[600px]'>
                    <div className='flex w-[343px] h-[44px] my-5'>
                        <button className={`w-1/2 rounded-l-full border-[1px] border-[#ebd9c9] font-semibold flex items-center justify-center pr-1
                            ${status === '未付款' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('未付款')}>
                            {status === '未付款' && <img alt='check' src={check} className='w-5 h-5 mr-[1px] ml-2' />}未付款</button>
                        <button className={`w-1/2 rounded-r-full border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                            ${status === '已付款' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已付款')}>
                            {status === '已付款' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已付款</button>
                    </div>
                    {wholesaleData && wholesaleData.data.length > 0 ? wholesaleData.data
                        .map((order, index) => (
                            <motion.div key={index} className='w-[343px] h-[60px] bg-white rounded-lg flex flex-col items-center p-2 mb-4 justify-between shadow-md cursor-pointer'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.4 }}
                                onClick={() => setShowDetail(order.supplier_order_id)}>
                                <p className='text-main-black w-full'>{order.supplier_product_name}</p>
                                <p className='text-main-brown text-xs w-full'>付款截止時間：{order.payment_deadline}</p>
                                {showDetail === order.supplier_order_id && 
                                    <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-10 cursor-default" onClick={() => setShowDetail(null)}>
                                        <div className="bg-white p-4 rounded-lg w-[343px] h-auto relative" onClick={(event) => event.stopPropagation()}>
                                            <img alt='close' src={xmark} className='absolute top-4 right-4 h-6 w-6 cursor-pointer' 
                                                onClick={() => setShowDetail(null)}/>
                                            <p className='text-main-black text-lg font-semibold mb-3'>訂單詳情</p>
                                            <div className='flex w-full'>
                                                <img alt='product' src={order.supplier_product_image} className='h-[110px] w-[110px] rounded-lg mr-3' />
                                                <div className='flex flex-col w-full'>
                                                    <p className='text-main-black font-medium mb-2'>{order.supplier_product_name}</p>
                                                    <p className='text-main-black font-medium mb-2'>{order.supplier_order_id}</p>
                                                    <div className='flex justify-between mb-2'>
                                                        <p className='text-main-black'>${order.price_at_order}</p>
                                                        <input className='text-main-black text-base w-14 text-center border-2 border-[#ebd9c9] rounded-full
                                                        focus:outline-none focus:border-[#f2ca7f]'
                                                        value={orderQuantities[order.supplier_order_id]}
                                                        onChange={(e) => handleQuantityChange(order.supplier_order_id, e.target.value)}
                                                        ></input>
                                                    </div>
                                                    <div className='flex justify-between mb-2'>
                                                        <p className='text-main-black'>總額</p>
                                                        <p className='text-[#e34a4f] font-semibold'>NT${order.total_amount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {status === '未付款' ? (
                                                <>
                                                    <div className='flex justify-between border-y-[1px] py-2 mb-4'>
                                                        <p className='text-main-black'>收件地址</p>
                                                        <p className='text-main-black'>{order.delivery_address}</p>
                                                    </div>
                                                    <div className='flex justify-center mb-4'>
                                                        <button className='text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[48px] rounded-full hover:text-deep-brown hover:border-deep-brown'
                                                            onClick={() => handleCancel(order)}>取消訂單</button>
                                                        <button className='text-white font-semibold bg-main-brown w-[190px] h-[48px] rounded-full ml-2 hover:bg-deep-brown'
                                                            onClick={() => handleChange(order)}>確認</button>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <p className='text-main-brown font-semibold mb-2'>匯款方式</p>
                                                        <p className='text-main-black font-medium'>請將款項匯至以下帳戶：</p>
                                                        <p className='text-main-black'>戶名：正誠餐飲國際有限公司</p>
                                                        <p className='text-main-black'>銀行：中國信託 822</p>
                                                        <p className='text-main-black mb-2'>帳號：716540-258499</p>
                                                        <p className='text-main-black font-medium text-[15px]'>匯款完成後</p>
                                                        <p className='text-main-black font-medium text-[15px]'>請回傳以下資訊至帳房先生官方LINE：</p>
                                                        <p className='text-main-black'>訂單編號：{order.supplier_order_id}</p>
                                                        <p className='text-main-black'>匯款帳號末五碼：[末五碼]</p>
                                                        <p className='text-main-black font-medium text-[15px] mt-2'>(官方line帳號：@373gznkg)</p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='flex justify-between border-y-[1px] py-2 mb-4'>
                                                    <p className='text-main-black'>收件地址</p>
                                                    <p className='text-main-black'>{order.delivery_address}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </motion.div>
                    )) : (<div className='flex flex-col items-center min-h-[500px] justify-center'>
                        <img alt='empty' src={emptybag} />
                        <p className='text-[#858585] mt-4'>您的批貨清單還是空的，</p>
                        <p className='text-[#858585]'>期待您開始添加商品！</p>
                    </div>)}
                </div>
                <div className='flex justify-end mt-10 mb-20 pb-20'>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer'>&lt;</button>
                    {currentPage > 2 && maxPage > 5 && (
                    <>
                        <button onClick={() => handlePageChange(1)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>1</button>
                        {currentPage > 3 && <span className='mr-3'>...</span>}
                    </>
                )}
                {getPageNumbers().map((page) => (
                    <button key={page} onClick={() => handlePageChange(page)} className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}>
                        {page}
                    </button>
                ))}
                {currentPage < (maxPage - 2) && maxPage >= 6 && (
                    <>
                        {currentPage < (maxPage - 2) && <span className='mr-3'>...</span>}
                        <button onClick={() => handlePageChange(maxPage)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>{maxPage}</button>
                    </>
                )}
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === maxPage} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer'>&gt;</button>
                </div>
            </div>
        </div>
    )
}

export default ManageWholesalePage;