import { configureStore } from '@reduxjs/toolkit';
import { userSlice, login, setAuthToken } from './slice/userSlice';
import { userApi } from './slice/apiSlice';

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        [userApi.reducerPath]: userApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});

const checkAuthentication = () => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      store.dispatch(setAuthToken(token));
      store.dispatch(login());
    }
};

checkAuthentication();  