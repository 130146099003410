import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import liff from '@line/liff';
import { setLoginPhone, setLoginPassword, login, setAuthToken, setLoginRole } from '../store/slice/userSlice';
import { useLoginUserMutation, useLineLoginMutation } from '../store/slice/apiSlice';
import success from '../images/success.svg';
import line from '../images/line.svg';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const phone = useSelector(state => state.user.login.phone);
    const password = useSelector(state => state.user.login.password);
    const token = useSelector(state => state.user.token);

    const [phoneError, setPhoneError] = useState(false);
    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        dispatch(setLoginPhone(newPhone));
        if (!/^09\d{8}$/.test(newPhone)) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    }

    useEffect(() => {
        if (token) {
            navigate('/home');
        }
    }, []);

    const handlePasswordChange = (e) => {
        dispatch(setLoginPassword(e.target.value));
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    const [showDiv, setShowDiv] = useState(false);
    const [userError, setUserError] = useState('');
    const [loginUser] = useLoginUserMutation();

    const handleLogin = async () => {
        setUserError('');

        try {
            const formData = new URLSearchParams();
            formData.append('username', phone);
            formData.append('password', password);

            const response = await loginUser(formData).unwrap();

            console.log(response);
            const token = response.access_token;
            const role = response.is_seller;
            if (token) {
                dispatch(setAuthToken(token));
                dispatch(setLoginRole(role));
                dispatch(login());
                setShowDiv(true);
        
                setTimeout(() => {
                    setShowDiv(false);
                    navigate('/home');
                }, 1500); 
            }
        } catch (error) {
            if (error.status === 403 && error.data.detail.includes('Invalid password or username')) {
                setUserError('請檢查是否輸入正確的手機或密碼');
            } else if (error.status === 404 && error.data.detail.includes('User not found')) {
                setUserError('請確認此號碼是否已註冊')
            } else if (error.status === 422) {
                setUserError('請檢查是否確實填寫手機與密碼')
            } else {
                console.error('登入失敗', error);
            }
        }
    };

    const [lineLogin] = useLineLoginMutation();
    const handleLineLogin = async () => {
        try {
            await liff.init({
                liffId: '2004697485-WAbN5GgO' // 你的 LIFF ID
            });
        
            if (!liff.isLoggedIn()) {
                console.log('正在登入...');
                liff.login({ redirectUri: window.location.href });
            } else {
                const lineProfile = await liff.getProfile();
                const user = {
                line_user_id: lineProfile.userId,
                line_user_name: lineProfile.displayName,
                };
                console.log('登入成功：', user);
                const response = await lineLogin(user).unwrap();
                const token = response.access_token;
                const role = response.is_seller;
                if (token) {
                dispatch(setAuthToken(token));
                dispatch(setLoginRole(role));
                dispatch(login());
                
                navigate('/home');
                }
            }
        } catch (error) {
          console.error('登入失敗', error);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const initializeLiff = async () => {
          try {
            await liff.init({ liffId: '2004697485-WAbN5GgO' });
            console.log("LIFF initialized");
            autoLoginIfRedirected();
        } catch (error) {
            console.error('LIFF Initialization failed', error);
        }
    };
    
    const autoLoginIfRedirected = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        
        if (liff.isLoggedIn() && code && state) {
            console.log('Attempting auto login');
            setIsLoading(true);
            handleLineLogin();
          } else {
            console.log('Not logged in via LIFF or required parameters are missing');
          }
        };
    
        initializeLiff();
      }, []);

    return (
        <div key='loginForm'>
            {isLoading && 
                <div className='z-10 fixed top-[166px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                    {/* <img alt='success' src={success} className='py-4' /> */}
                    <p className='text-[#45a5de] font-semibold text-2xl py-4'>Line登入進行中，請稍候...</p>
                </div>
            }
            {showDiv && 
                <div className='z-10 fixed top-[166px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                    <img alt='success' src={success} className='py-4' />
                    <p className='text-[#45a5de] font-semibold text-2xl pl-2'>登入成功</p>
                </div>
            }
            <div className='flex flex-col items-center py-8 px-4'>
            <div className="w-[343px] relative">
                <input placeholder='請輸入手機號碼'
                    className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 mb-6 login-phone
                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                    ${phoneError ? 'border-2 border-[#ff5b60]' : ''}`}
                    value={phone}
                    onChange={handlePhoneChange}
                    ></input>
                    {phoneError ? <div className='text-[#ff5b60] text-xs absolute top-[54px] left-[10px]'>手機號碼應為10個數字</div> : ''}
            </div>
                <div className='relative'>
                    <input placeholder='請輸入包含英文和數字的密碼'
                        className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 login-password
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]`}
                        value={password}
                        onChange={handlePasswordChange}
                        type={passwordShown ? 'text' : 'password'}></input>
                    {passwordShown ? <div className="eye-slash-icon eye-icon-all text-main-brown" onClick={togglePasswordVisibility}></div> : <div className="eye-icon eye-icon-all text-main-brown" onClick={togglePasswordVisibility}></div>}
                    {userError && <p className="text-[#ff5b60] text-xs absolute top-[56px] left-[10px]">{userError}</p>}
                </div>
            </div>
            <div className='flex flex-col items-center py-8 px-4'>
                <Link to='/forgetpassword' className='text-main-brown underline underline-offset-4 font-bold hover:text-deep-brown'>忘記密碼？</Link>
                <button className='bg-main-brown text-white font-semibold py-4 w-[343px] my-3 rounded-full hover:bg-deep-brown'
                    onClick={handleLogin}>登入</button>
                <button className='bg-[#00c300] text-white font-semibold py-3 w-[343px] rounded-full flex justify-center items-center hover:opacity-90'
                    onClick={handleLineLogin}>
                    <img alt='line' src={line} className='flex w-8 h-8'/><p className='flex'>使用Line繼續</p>
                </button>
            </div>
        </div> 
    )
}

export default Login;