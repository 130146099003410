import React, { useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';
import img1 from '../images/img-1.png';
import img2 from '../images/img-2.png';
import img3 from '../images/img-3.png';
import img4 from '../images/img-4.png';
import img5 from '../images/img-5.png';
import img6 from '../images/img-6.png';
import img7 from '../images/img-7.png';
import img8 from '../images/img-8.png';
import img9 from '../images/img-9.png';
import img10 from '../images/img-10.png';
import img11 from '../images/img-11.png';
import img12 from '../images/img-12.png';
import img13 from '../images/img-13.png';
import img14 from '../images/img-14.png';
import img15 from '../images/img-15.png';
import img16 from '../images/img-16.png';
import img17 from '../images/img-17.png';
import img18 from '../images/img-18.png';
import img19 from '../images/img-19.png';
import bg1 from '../images/bg-1.png';
import bg2 from '../images/bg-2.png';
import bg3 from '../images/bg-3.png';
import bg4 from '../images/bg-4.png';
import seller from '../images/seller.svg';
import buyer from '../images/buyer.svg';
import search from '../images/search.svg';
import xmark from '../images/xmark.svg';

function IntroductionPage() {
  const [popup, setPopup] = useState(false);

  return (
    <div className="w-full h-auto flex flex-col relative">
      {popup && (
        <div
          className="fixed bg-black/60 inset-0 flex justify-center items-center z-30"
          onClick={() => setPopup(false)}
        >
          <div
            className="bg-white rounded-lg relative p-8 w-[300px] lg:w-[800px] h-[266px] lg:h-[316px] flex flex-col items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              alt="xmark"
              src={xmark}
              className="h-8 absolute top-4 right-4 cursor-pointer"
              onClick={() => setPopup(false)}
            />
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-[32px] font-semibold">
                若有興趣加入
              </p>
              <p className="text-xl lg:text-[32px] font-semibold lg:ml-2">
                歡迎聯絡我們
              </p>
            </div>
            <p className="text-[#F38537] text-base lg:text-2xl mt-2 lg:mt-8">
              團購主目前為邀請制
            </p>
            <p className="text-main-brown text-base lg:text-2xl text-center">
              聯繫請洽Line小編 搜尋：@373gznkg
            </p>
            <p className="text-main-brown text-base lg:text-2xl">
              (記得要加@喔)
            </p>
          </div>
        </div>
      )}
      <div className="w-full h-[72px] bg-white relative flex justify-center py-3">
        <img alt="logo" src={logo} className="h-11" />
      </div>
      <div className="flex flex-col items-center w-full h-full bg-[#f5f5f5] overflow-hidden relative z-10 p-6">
        <Parallax
          translateY={[-60, 65]}
          className="absolute z-0 top-[-300px] -translate-x-1/2 -translate-y-1/2 bg-gradient-to-b from-[#EBD9C9]/90 from-5% to-white to-100% w-[1100px] lg:w-[2000px] h-[1000px] rounded-t-full"
        ></Parallax>
        <div className="flex flex-col-reverse lg:flex-row items-center lg:items-start justify-center z-10 h-auto pt-2 lg:pt-16">
          <div className="flex flex-col lg:hidden">
            <button
              className="bg-[#45A5DE] text-white text-lg w-[300px] h-[50px] rounded-lg mt-10"
              onClick={() => setPopup(true)}
            >
              立即加入
            </button>
            <Link to="/login">
              <button className="text-[#45A5DE] border border-[#45a5de] bg-white text-lg w-[300px] h-[50px] rounded-lg mt-6">
                直接登入
              </button>
            </Link>
          </div>
          <div className="relative grid grid-cols-3 gap-x-2 lg:gap-x-4 w-[343px] lg:w-[606px] mt-8 place-items-center">
            <img
              src={img1}
              alt="img1"
              className="h-[218px] lg:h-[378px] drop-shadow-md mt-20"
            />
            <img
              src={img2}
              alt="img2"
              className="h-[246x] lg:h-[409px] translate-y-[-30px]"
            />
            <img
              src={img3}
              alt="img3"
              className="h-[218px] lg:h-[378px] drop-shadow-md mt-20"
            />
          </div>
          <div className="flex flex-col w-[468px] items-center ml-0 lg:ml-[52px] lg:mt-9">
            <p className="text-[32px] lg:text-[56px] font-semibold">
              一支手機當微商
            </p>
            <p className="text-[32px] lg:text-[56px] font-semibold">
              開啟你的
              <span className="text-main-brown underline underline-offset-8 lg:underline-offset-[16px] decoration-[3px] decoration-[#F2CA7F]">
                創業旅程
              </span>
            </p>
            <button
              className="bg-[#45A5DE] text-white text-lg w-[300px] h-[50px] rounded-lg mt-10 hidden lg:block
              "
              onClick={() => setPopup(true)}
            >
              立即加入
            </button>
            <Link to="/login">
              <button className="text-[#45A5DE] border border-[#45a5de] bg-white text-lg w-[300px] h-[50px] rounded-lg mt-6 hidden lg:block">
                直接登入
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-main-brown mt-12 mb-10 flex flex-col items-center w-[343px] lg:w-[1128px] h-auto rounded-2xl relative p-4 lg:p-10">
          <p className="text-white text-2xl lg:text-[40px] font-semibold">
            帳房先生可以做什麼？
          </p>
          <div className="flex justify-between w-full mt-7">
            <div className="flex flex-col items-center w-1/3">
              <div className="bg-white w-20 lg:w-[150px] h-20 lg:h-[150px] rounded-full flex justify-center items-center">
                <img alt="seller" src={seller} className="h-12 lg:h-20" />
              </div>
              <p className="text-[#EBD9C9] text-base lg:text-xl mt-[10px]">
                成為團購主
              </p>
              <p className="text-[#EBD9C9] text-base lg:text-xl">當微商創業</p>
            </div>
            <div className="flex flex-col items-center w-1/3">
              <div className="bg-white w-20 lg:w-[150px] h-20 lg:h-[150px] rounded-full flex justify-center items-center">
                <img alt="search" src={search} className="h-12 lg:h-20" />
              </div>
              <p className="text-[#EBD9C9] text-base lg:text-xl mt-[10px]">
                可批貨下單
              </p>
              <p className="text-[#EBD9C9] text-base lg:text-xl">找貨免煩惱</p>
            </div>
            <div className="flex flex-col items-center w-1/3">
              <div className="bg-white w-20 lg:w-[150px] h-20 lg:h-[150px] rounded-full flex justify-center items-center">
                <img alt="seller" src={buyer} className="h-9 lg:h-16" />
              </div>
              <p className="text-[#EBD9C9] text-base lg:text-xl mt-[10px]">
                成為跟團仔
              </p>
              <p className="text-[#EBD9C9] text-base lg:text-xl">跟團買便宜</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#C0CFD4] h-auto flex flex-col relative lg:p-20 py-8 px-4 items-center z-10">
        <p className="text-2xl lg:text-[40px] font-semibold">
          如何開始使用帳房先生？
        </p>
        <div className="w-[343px] lg:w-[1128px] flex items-center mt-8">
          <div className="bg-[#F38537] w-6 lg:w-8 h-6 lg:h-8 flex justify-center items-center text-lg text-[#C0CFD4] rounded-full font-semibold lg:pb-1 pl-[1px]">
            1
          </div>
          <p className="text-xl lg:text-[32px] font-semibold ml-1">
            註冊成為<span className="text-main-brown">團購主</span>
          </p>
        </div>
        <div className="grid grid-cols-6 lg:grid-cols-5 gap-x-2 lg:gap-x-10 gap-y-6 w-[343px] lg:w-[1128px] mt-8 place-items-center">
          <img
            src={img4}
            alt="img4"
            className="h-[246px] lg:h-[398px] col-span-3 lg:col-span-1"
          />
          <img
            src={img5}
            alt="img5"
            className="h-[246px] lg:h-[398px] col-span-3 lg:col-span-1"
          />
          <img
            src={img6}
            alt="img6"
            className="h-[237px] lg:h-[398px] drop-shadow-md col-span-2 lg:col-span-1"
          />
          <img
            src={img7}
            alt="img7"
            className="h-[237px] lg:h-[398px] drop-shadow-md col-span-2 lg:col-span-1"
          />
          <img
            src={img8}
            alt="img8"
            className="h-[237px] lg:h-[398px] drop-shadow-md col-span-2 lg:col-span-1"
          />
        </div>
        <div className="flex flex-col w-[343px] lg:w-[1128px] mt-8">
          <p className="text-lg lg:text-2xl">
            成為團購主可使用
            <span className="text-main-brown font-semibold">開團功能</span>。
          </p>
          <p className="text-lg lg:text-2xl">
            設定開團日期跟取貨地點，建立開團商品資訊，即可產出跟團連結與QR code
            <span className="font-semibold text-main-brown">分享在社群！</span>
          </p>
        </div>
      </div>
      <div className="bg-[#f5f5f5] w-full h-full flex flex-col items-center py-8 lg:py-20 px-[157px] relative z-0 overflow-hidden">
        <Parallax
          translateY={[-60, 65]}
          className="absolute left-[-50px] lg:left-0 top-[-70px]"
        >
          <img className="z-0 w-[220px] lg:w-[470px]" alt="bg1" src={bg1} />
        </Parallax>
        <Parallax
          translateY={[-60, 65]}
          className="absolute right-[-50px] lg:right-0 top-[430px]"
        >
          <img className="z-0 w-[220px] lg:w-[570px]" alt="bg2" src={bg2} />
        </Parallax>
        <div className="w-[343px] lg:w-[1128px] flex items-center relative">
          <div className="bg-[#F38537] w-6 lg:w-8 h-6 lg:h-8 flex justify-center items-center text-lg text-[#f5f5f5] rounded-full font-semibold lg:pb-[3px] pl-[1px]">
            2
          </div>
          <p className="text-xl lg:text-[32px] font-semibold ml-1">
            可直接<span className="text-main-brown">批發商品</span>
          </p>
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center z-10 pt-8 lg:pt-16">
          <div className="w-[276px] lg:w-[448px] grid grid-cols-2 gap-x-7 lg:gap-x-14">
            <img src={img9} alt="img9" className="h-[246px] lg:h-[378px]" />
            <img
              src={img10}
              alt="img10"
              className="h-[246px] lg:h-[378px] mt-12"
            />
          </div>
          <div className="flex flex-col w-[343px] lg:w-[566px] items-start lg:items-center lg:ml-8 mt-4 lg:mt-[200px]">
            <p className="text-lg lg:text-2xl">
              團購主
              <span className="text-main-brown font-semibold">
                可瀏覽大盤商批發商品
              </span>
              ，享有
              <span className="text-main-brown font-semibold">批發價！</span>
            </p>
            <p className="text-lg lg:text-2xl">選擇想要開團的商品下單。</p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center lg:items-start justify-center z-10 pt-8 lg:pt-16">
          <div className="flex flex-col w-[343px] lg:w-[506px] items-start lg:items-center lg:mr-8 mt-4 lg:mt-[200px] text-start lg:text-center">
            <p className="text-lg lg:text-2xl">
              在批發商品頁面點選快速開團，即可
              <span className="text-main-brown font-semibold">
                自動代入商品資訊
              </span>
              ，迅速開團！
            </p>
            <p className="text-lg lg:text-2xl">
              （使用自己叫貨的商品也可以開團唷！）
            </p>
          </div>
          <div className="w-[276px] lg:w-[448px] grid grid-cols-2 gap-x-7 lg:gap-x-14">
            <img src={img11} alt="img11" className="h-[246px] lg:h-[378px]" />
            <img
              src={img12}
              alt="img12"
              className="h-[246px] lg:h-[378px] mt-12"
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#C0CFD4] h-auto flex flex-col relative z-10 p-8 lg:p-20 items-center">
        <div className="w-[343px] lg:w-[1128px] flex items-center">
          <div className="bg-[#F38537] w-6 lg:w-8 h-6 lg:h-8 flex justify-center items-center text-lg text-[#C0CFD4] rounded-full font-semibold lg:pb-1 pl-[1px]">
            3
          </div>
          <p className="text-2xl lg:text-[32px] font-semibold ml-1">
            跟團仔+1<span className="text-main-brown">快速跟團</span>
          </p>
        </div>
        <div className="grid grid-cols-6 gap-x-8 lg:gap-x-10 w-[276px] lg:w-[696px] mt-8 place-items-center">
          <img
            src={img13}
            alt="img13"
            className="h-[246px] lg:h-[398px] lg:mt-[50px] col-span-6 lg:col-span-2"
          />
          <img
            src={img14}
            alt="img14"
            className="h-[246px] lg:h-[398px] col-span-3 lg:col-span-2"
          />
          <img
            src={img15}
            alt="img15"
            className="h-[246px] lg:h-[398px] mt-[50px] col-span-3 lg:col-span-2"
          />
        </div>
        <div className="flex flex-col w-[343px] lg:w-[1128px] mt-8">
          <p className="text-lg lg:text-2xl">
            於跟團仔頁面
            <span className="text-main-brown font-semibold">輸入團購代碼</span>
            ，或
            <span className="text-main-brown font-semibold">
              點選團購主分享連結
            </span>
            ，選擇取貨地點下單，等待商品到貨。
          </p>
        </div>
        <div className="flex items-start justify-start z-10 h-[360px] lg:h-[520px] w-[343px] lg:w-[1128px] pt-16">
          <div className="relative w-[122px] lg:w-[200px]">
            <img
              src={img16}
              alt="img16"
              className="h-[246px] lg:h-[388px] absolute top-0 left-0"
            />
            <div className="relative">
              <div className="w-[30px] lg:w-[50px] h-[3px] bg-[#ff5b60] absolute top-[62px] lg:top-[120px] right-[-8px] lg:right-[-16px]"></div>
              <div className="w-[3px] h-[50px] lg:h-[20px] bg-[#ff5b60] absolute top-[12px] lg:top-[100px] right-[-8px] lg:right-[-16px]"></div>
              <div className="w-[20px] h-[3px] bg-[#ff5b60] absolute top-[12px] lg:top-[100px] right-[-28px] lg:right-[-36px]"></div>
              <p className="text-lg lg:text-2xl absolute w-[192px] lg:w-[862px] left-[160px] lg:left-[260px] top-[-36px] lg:top-[80px]">
                輸入團購代碼後，即可進入團購選購商品。
              </p>
            </div>
            <div className="relative">
              <div className="w-[50px] lg:w-[73px] h-[3px] bg-[#ff5b60] absolute top-[120px] lg:top-[190px] right-[-26px] lg:right-[-36px]"></div>
              <p className="text-lg lg:text-2xl absolute w-[192px] lg:w-[862px] left-[160px] lg:left-[260px] top-[30px] lg:top-[160px]">
                團購主掃描跟團仔的取貨連結後，即可顯示該跟團者向此團購主訂購的所有商品，並完成快速取貨。
              </p>
            </div>
            <div className="relative">
              <div className="w-[30px] lg:w-[50px] h-[3px] bg-[#ff5b60] absolute top-[170px] lg:top-[270px] right-[-8px] lg:right-[-16px]"></div>
              <div className="w-[3px] h-[50px] lg:h-[20px] bg-[#ff5b60] absolute top-[170px] lg:top-[270px] right-[-8px] lg:right-[-16px]"></div>
              <div className="w-[20px] h-[3px] bg-[#ff5b60] absolute top-[217px] lg:top-[287px] right-[-28px] lg:right-[-36px]"></div>
              <p className="text-lg lg:text-2xl absolute w-[192px] lg:w-[862px] left-[160px] lg:left-[260px] top-[180px] lg:top-[270px]">
                可以查看訂購商品的到貨狀態，若發現重複訂購，可在此進行訂單取消操作。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#f5f5f5] w-full h-full flex flex-col items-center py-8 lg:py-20 px-[157px] relative overflow-hidden">
        <Parallax
          translateY={[-60, 65]}
          className="absolute left-[-30px] lg:left-0 top-[160px] lg:top-[310px]"
        >
          <img className="z-0 w-[140px] lg:w-[350px]" alt="bg3" src={bg3} />
        </Parallax>
        <Parallax
          translateY={[-60, 65]}
          className="absolute right-0 top-[-170px]"
        >
          <img className="z-0 w-[140px] lg:w-[330px]" alt="bg4" src={bg4} />
        </Parallax>
        <div className="w-[343px] lg:w-[1128px] flex items-center relative">
          <div className="bg-[#F38537] w-6 lg:w-8 h-6 lg:h-8 flex justify-center items-center text-lg text-[#f5f5f5] rounded-full font-semibold lg:pb-[3px] pl-[1px]">
            4
          </div>
          <p className="text-2xl lg:text-[32px] font-semibold ml-1">
            跟團仔<span className="text-main-brown">領取商品</span>
          </p>
        </div>
        <div className="flex flex-col items-center z-10 w-[1128px] mb-6">
          <div className="grid grid-cols-3 lg:gap-x-10 mt-10 w-[343px] lg:w-[696px]">
            <img src={img17} alt="img17" className="h-[210px] lg:h-[388px]" />
            <img
              src={img18}
              alt="img18"
              className="h-[210px] lg:h-[388px] mt-[50px]"
            />
            <img src={img19} alt="img19" className="h-[210px] lg:h-[388px]" />
          </div>
          <div className="flex flex-col lg:text-center w-[343px] lg:w-[1126px] items-center ml-8 mt-10">
            <p className="text-base lg:text-2xl">
              商品到貨通知後，即可
              <span className="text-main-brown font-semibold">
                前往取貨地點
              </span>
              ，掃QR code領取商品，即可完成交易。
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-auto bg-white py-8 lg:p-20 flex flex-col items-center relative">
        <div className="flex flex-col lg:flex-row">
          <p className="text-xl lg:text-[32px] font-semibold">若有興趣加入</p>
          <p className="text-xl lg:text-[32px] font-semibold lg:ml-2">
            歡迎聯絡我們
          </p>
        </div>
        <p className="text-[#F38537] text-base lg:text-2xl mt-2 lg:mt-8">
          團購主目前為邀請制
        </p>
        <p className="text-main-brown text-base lg:text-2xl">
          聯繫請洽Line小編 搜尋：@373gznkg
        </p>
        <p className="text-main-brown text-base lg:text-2xl"> (記得要加@喔)</p>
        <p className="text-black text-base lg:text-2xl mt-6 lg:mt-8">
          已有帳號 or 想成為跟團仔
        </p>
        <Link to="/login">
          <button className="text-[#45A5DE] border border-[#45a5de] bg-white text-lg w-[300px] h-[50px] rounded-lg mt-2 lg:mt-8">
            直接進入
          </button>
        </Link>
      </div>
      <div className="w-full relative bg-main-brown h-12 flex justify-center items-center text-xs lg:text-base">
        <p className="text-white">2024 © 帳房先生 || 正啟科技股份有限公司</p>
      </div>
    </div>
  );
}

export default IntroductionPage;
