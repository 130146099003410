import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetCampaignsNameQuery, useGetCampaignsDetailQuery } from '../store/slice/apiSlice';
import { motion } from 'framer-motion';
import left from '../images/left.svg';
import share from '../images/share.svg';
import line from '../images/line.svg';
import copy from '../images/copy.svg';
import download from '../images/download.svg';
import xmark from '../images/xmark.svg';
import success from '../images/success_green.svg';
import emptybox from '../images/emptybox.svg';

function GroupBuyingPage() {
    const { data: campaignsData } = useGetCampaignsNameQuery({ campaign_status: '開團中' });
    const [campaignId, setCampaignId] = useState('');

    useEffect(() => {
        if (campaignsData && campaignsData.data && campaignsData.data.length > 0) {
            setCampaignId(campaignsData.data[0].campaign_id);
        }
    }, [campaignsData]);

    const { data } = useGetCampaignsDetailQuery(campaignId || skipToken);

    useEffect(() => {
        console.log(campaignsData);
        console.log(campaignId);
        console.log(data);
    }, [campaignsData, campaignId, data]);

    const handleCampaignChange = (event) => {
        setCampaignId(event.target.value);
    };

    const [showDiv, setShowDiv] = useState(false);
    function handleShareToLine() {
        if (data) {
            const url = `https://plus1.com.tw/purchase/${data.data.id}`;
            const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}`;
            window.open(lineShareUrl, '_blank');
        }
    }
    
    const [linkCopy, setLinkCopy] = useState(false);
    const handleCopyLink = () => {
        if (data) {
            navigator.clipboard.writeText(`https://plus1.com.tw/purchase/${data.data.id}`)
                .then(() => {
                    console.log('連結已複製');
                    setLinkCopy(true);

                    setTimeout(() => {
                        setLinkCopy(false);
                    }, 600);
                })
                .catch((error) => console.log('複製失敗', error));
        }
    }

    const [codeCopy, setCodeCopy] = useState(false);
    const handleCopyCode = () => {
        if (data) {
            navigator.clipboard.writeText(data.data.code)
                .then(() => {
                    console.log('代號已複製');
                    setCodeCopy(true);

                    setTimeout(() => {
                        setCodeCopy(false);
                    }, 600);
                })
                .catch((error) => console.log('複製失敗', error));
        }
    }
    
    return (
        <div className="bg-[#f5f5f5] w-full h-screen flex flex-col items-center">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/seller'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">團購連結</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <select className='text-main-black w-[343px] h-[44px] rounded-full px-3 my-4 border-[1px] border-[#ebd9c9] appearance-none arrowdown
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        onChange={handleCampaignChange}
                        value={campaignId}>
                {campaignsData && campaignsData.data.map((campaign, index) => (
                    <option key={index} value={campaign.campaign_id}>{campaign.name}</option>
                ))}
            </select>
            {data ? 
            <motion.div className='w-[343px] h-auto flex flex-col bg-white rounded-lg p-4 relative shadow-md'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}>
                <img alt='share' src={share} className='h-10 w-10 absolute right-4 top-4 cursor-pointer hover:opacity-80'
                    onClick={() => setShowDiv(true)} />
                <p className='text-main-black font-semibold text-lg mb-2'>團購代號</p>
                <p className='text-[#8a8a97] mb-4'>{data.data.code}</p>
                <p className='text-main-black font-semibold text-lg mb-2'>團購連結</p>
                <p className='text-[#8a8a97] mb-4 break-words'>{`https://plus1.com.tw/purchase/${data.data.id}`}</p>
                <p className='text-main-black font-semibold text-lg mb-2'>團購條碼</p>
                <img alt='qrcode' src={data.data.qr_code} className='w-[300px] h-[300px]'/>
            </motion.div>
            :(<div className='flex flex-col items-center min-h-[500px] justify-center'>
                <img alt='empty' src={emptybox} />
                <p className='text-[#858585] mt-4'>還沒有進行中的團購？</p>
                <p className='text-[#858585]'>讓我們一起創造驚人的銷售奇蹟！</p>
            </div>)}
            {showDiv && (
                <div className='fixed inset-0 bg-black/60 flex justify-center items-center' onClick={() => setShowDiv(false)}>
                    <div className='bg-white rounded-lg flex flex-col py-3 px-4 items-center relative' onClick={(e) => e.stopPropagation()}>
                        <p className='text-main-black font-semibold text-lg mb-2 border-b-[1px] border-[#e1e0f1] pb-2 w-full flex justify-center'>分享給您的親朋好友！</p>
                        <img alt='xmark' src={xmark} className='h-5 w-5 absolute right-4 cursor-pointer'
                            onClick={() => setShowDiv(false)}/>
                        <div className='flex mb-4'>
                            <div className='flex items-center p-4 rounded-xl shadow-md mr-4 cursor-pointer hover:opacity-90'
                                onClick={handleShareToLine}>
                                <img alt='line' src={line}/>
                                <p className='text-main-brown font-semibold ml-2'>分享連結</p>
                            </div>
                            <div className='flex items-center p-4 rounded-xl shadow-md cursor-pointer hover:opacity-90'
                                onClick={handleCopyLink}>
                                {linkCopy ? <img alt='success' src={success} className='mx-9 w-10 h-10' /> : <>
                                <img alt='copy' src={copy}/>
                                <p className='text-main-brown font-semibold ml-2'>複製連結</p>
                                </>}
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='flex items-center p-4 rounded-xl shadow-md mr-4 cursor-pointer hover:opacity-90'
                                onClick={handleCopyCode}>
                                {codeCopy ? <img alt='success' src={success} className='mx-9 w-10 h-10' /> : <>
                                <img alt='copy' src={copy}/>
                                <p className='text-main-brown font-semibold ml-2'>複製代號</p>
                                </>}
                            </div>
                            <a href={data.data.qr_code} download="qrcode.png" className='flex items-center p-4 rounded-xl shadow-md cursor-pointer hover:opacity-90'>
                                <img alt='download' src={download}/>
                                <p className='text-main-brown font-semibold ml-2'>下載條碼</p>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default GroupBuyingPage;