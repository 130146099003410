import {
  createApi,
  fetchBaseQuery,
  skipToken,
} from '@reduxjs/toolkit/query/react';

const baseApiUrl = 'https://plus1.com.tw/api';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const needAuth = [
        'buyerQrcode',
        'userInfo',
        'getNotificationsCount',
        'getNotifications',
        'getCampaigns',
        'getCampaignsDetail',
        'updateCampaigns',
        'searchCampaigns',
        'createCampaigns',
        'campaignStatusUpdate',
        'createProducts',
        'duplicateProducts',
        'updateProducts',
        'deleteCampaignProducts',
        'getCampaignsName',
        'getSupplierProducts',
        'createSupplierOrders',
        'getSupplierOrders',
        'updateSupplierOrders',
        'getSupplierProductsDetail',
        'createOrders',
        'getOrders',
        'deleteOrders',
        'sellerOrders',
        'sellerOrdersUpdate',
        'sellerOrdersDetail',
        'buyerOrdersDetail',
      ];
      if (needAuth.includes(endpoint)) {
        const token = getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }

      return headers;
    },
  }),
  tagTypes: ['Orders', 'Campaigns', 'Wholesale', 'Notification', 'User'],
  endpoints: (builder) => ({
    //user
    registerUser: builder.mutation({
      query: (user) => ({
        url: '/register',
        method: 'POST',
        body: user,
      }),
    }),
    loginUser: builder.mutation({
      query: (user) => ({
        url: '/login',
        method: 'POST',
        body: user,
      }),
    }),
    lineLogin: builder.mutation({
      query: (lineUserData) => ({
        url: '/line_login',
        method: 'POST',
        body: lineUserData,
      }),
    }),
    sendSms: builder.mutation({
      query: (user) => ({
        url: '/send_sms',
        method: 'POST',
        body: user,
      }),
    }),
    buyerQrcode: builder.query({
      query: () => '/buyer/qrcode',
    }),
    userInfo: builder.query({
      query: () => '/user/info',
      providesTags: ['User'],
    }),
    resetPassword: builder.mutation({
      query: (user) => ({
        url: `/password-reset`,
        method: 'PATCH',
        body: user,
      }),
    }),
    getNotificationsCount: builder.query({
      query: () => '/buyer/notifications/unread_count',
      providesTags: ['Notification'],
    }),
    getNotifications: builder.query({
      query: ({ page, page_size }) => ({
        url: '/buyer/notifications',
        params: { page, page_size },
      }),
      providesTags: ['Notification'],
    }),

    // campaign
    getCampaigns: builder.query({
      query: ({ campaign_status, campaign_id, page, page_size }) => ({
        url: '/seller/campaigns',
        params: { campaign_status, campaign_id, page, page_size },
      }),
      providesTags: ['Campaigns'],
    }),
    getCampaignsDetail: builder.query({
      query: (campaignId) => `/seller/campaigns/${campaignId}`,
      providesTags: (result, error, campaignId) => [
        { type: 'Campaigns', id: campaignId },
      ],
    }),
    updateCampaigns: builder.mutation({
      query: (campaignData) => ({
        url: `/seller/campaigns/${campaignData.campaignId}`,
        method: 'PUT',
        body: campaignData,
      }),
      invalidatesTags: (result, error, { campaignId }) => [
        { type: 'Campaigns', id: campaignId },
      ],
    }),
    searchCampaigns: builder.query({
      query: ({ campaignId, campaignCode }) => {
        if (campaignId) {
          return `/campaigns?campaign_id=${encodeURIComponent(campaignId)}`;
        } else if (campaignCode) {
          return `/campaigns?campaign_code=${encodeURIComponent(campaignCode)}`;
        }
        throw new Error('Either campaignId or campaignCode must be provided');
      },
      providesTags: (result) =>
        result ? [{ type: 'Campaigns', id: result.data.id }] : ['Campaigns'],
    }),
    createCampaigns: builder.mutation({
      query: (data) => ({
        url: '/campaigns',
        method: 'POST',
        body: data,
      }),
    }),
    campaignStatusUpdate: builder.mutation({
      query: ({ campaign_id, status }) => ({
        url: `/seller/campaigns/${campaign_id}/status`,
        method: 'PATCH',
        body: status,
      }),
      invalidatesTags: ['Campaigns', 'Orders', 'Notification'],
    }),
    deleteCampaignProducts: builder.mutation({
      query: ({ campaign_id, product_id }) => ({
        url: `/seller/campaigns/${campaign_id}/products/${product_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Campaigns' }],
    }),
    getCampaignsName: builder.query({
      query: (campaign_status) => ({
        url: '/seller/campaigns/mini',
        params: campaign_status,
      }),
      providesTags: ['Campaigns'],
    }),

    // product
    createProducts: builder.mutation({
      query: (formData) => ({
        url: `/campaigns/${formData.get('campaign_id')}/products`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, formData) => [
        { type: 'Campaigns', id: formData.get('campaign_id') },
      ],
    }),
    duplicateProducts: builder.mutation({
      query: (formData) => ({
        url: `/campaigns/${formData.get('campaign_id')}/products/repeat`,
        method: 'POST',
        body: formData,
        meta: { campaign_id: formData.get('campaign_id') },
      }),
      invalidatesTags: ({ campaign_id }) => [
        { type: 'Campaigns', id: campaign_id },
      ],
    }),
    updateProducts: builder.mutation({
      query: ({ campaign_id, productId, formData }) => ({
        url: `/campaigns/${campaign_id}/products/${productId}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ({ campaign_id }) => [
        { type: 'Campaigns', id: campaign_id },
      ],
    }),

    //supplier
    getSupplierProducts: builder.query({
      query: ({
        product_name,
        main_category_id,
        sub_category_id,
        sort_by,
        order_by,
        page,
        page_size,
      }) => ({
        url: '/supplier_products',
        params: {
          product_name,
          main_category_id,
          sub_category_id,
          sort_by,
          order_by,
          page,
          page_size,
        },
      }),
      providesTags: ['Wholesale'],
    }),
    createSupplierOrders: builder.mutation({
      query: (formData) => ({
        url: '/seller/supplier_orders',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Wholesale', 'User'],
    }),
    getSupplierOrders: builder.query({
      query: ({ order_status, page, page_size }) => ({
        url: '/seller/supplier_orders',
        params: { order_status, page, page_size },
      }),
      providesTags: ['Wholesale'],
    }),
    updateSupplierOrders: builder.mutation({
      query: ({ supplier_order_id, data }) => ({
        url: `/seller/supplier_orders/${supplier_order_id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Wholesale'],
    }),
    getSupplierProductsDetail: builder.query({
      query: (supplier_product_id) =>
        `/supplier_products/${supplier_product_id}`,
      providesTags: (result, error, supplier_product_id) => [
        { type: 'Wholesale', id: supplier_product_id },
      ],
    }),

    // order
    createOrders: builder.mutation({
      query: (data) => ({
        url: '/orders',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Orders', 'Campaigns'],
    }),
    getOrders: builder.query({
      query: ({ order_status, page, page_size }) => ({
        url: '/buyer/orders',
        params: { order_status, page, page_size },
      }),
      providesTags: (result) =>
        result && result.orders
          ? [
              ...result.orders.map((order) => ({
                type: 'Orders',
                id: order.id,
              })),
              'Orders',
            ]
          : ['Orders'],
    }),
    deleteOrders: builder.mutation({
      query: (order_id) => ({
        url: `/orders/${order_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Orders' }],
    }),
    sellerOrders: builder.query({
      query: ({
        order_status,
        campaign_id,
        campaign_name,
        buyer_phone,
        buyer_id,
        order_by,
        page,
        page_size,
      }) => ({
        url: 'seller/orders',
        params: {
          order_status,
          campaign_id,
          campaign_name,
          buyer_phone,
          buyer_id,
          order_by,
          page,
          page_size,
        },
      }),
      providesTags: (result) =>
        result && result.orders
          ? [
              ...result.orders.map((order) => ({
                type: 'Orders',
                id: order.id,
              })),
              { type: 'Orders', id: 'LIST' },
              'Orders',
            ]
          : ['Orders'],
    }),
    sellerOrdersUpdate: builder.mutation({
      query: (order) => ({
        url: '/seller/orders',
        method: 'PUT',
        body: order,
      }),
      invalidatesTags: (result, error, { order_id }) => [
        { type: 'Orders', id: order_id },
        { type: 'Orders', id: 'LIST' },
        { type: 'Orders' },
      ],
    }),
    sellerOrdersDetail: builder.query({
      query: (order_id) => `/seller/orders/${order_id}`,
      providesTags: (result, error, order_id) => [
        { type: 'Orders', id: order_id },
      ],
    }),
    buyerOrdersDetail: builder.query({
      query: (order_id) => `/buyer/orders/${order_id}`,
      providesTags: (result, error, order_id) => [
        { type: 'Orders', id: order_id },
      ],
    }),

    // category
    categories: builder.query({
      query: () => '/categories',
    }),
    getCategoryId: builder.query({
      query: () => '/v2/categories',
    }),

    //  time
    getRemainingTime: builder.query({
      queryFn: (endTime = skipToken) => {
        if (endTime === skipToken) return { data: null };
        const now = new Date().getTime();
        const end = new Date(endTime).getTime();
        const remainingTime = end - now;
        if (remainingTime <= 0) {
          return { data: null }; // Countdown finished
        }
        return { data: remainingTime };
      },
      // This part is important to continuously poll the endpoint
      keepUnusedDataFor: 0, // Do not cache results
      pollingInterval: 1000, // Update every second
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useLineLoginMutation,
  useSendSmsMutation,
  useBuyerQrcodeQuery,
  useUserInfoQuery,
  useResetPasswordMutation,
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useGetCampaignsQuery,
  useGetCampaignsDetailQuery,
  useLazyGetCampaignsDetailQuery,
  useUpdateCampaignsMutation,
  useGetCampaignsNameQuery,
  useSearchCampaignsQuery,
  useLazySearchCampaignsQuery,
  useCreateCampaignsMutation,
  useCampaignStatusUpdateMutation,
  useCreateProductsMutation,
  useDuplicateProductsMutation,
  useUpdateProductsMutation,
  useDeleteCampaignProductsMutation,
  useGetSupplierProductsQuery,
  useCreateSupplierOrdersMutation,
  useGetSupplierOrdersQuery,
  useUpdateSupplierOrdersMutation,
  useGetSupplierProductsDetailQuery,
  useCreateOrdersMutation,
  useGetOrdersQuery,
  useDeleteOrdersMutation,
  useSellerOrdersQuery,
  useLazySellerOrdersQuery,
  useSellerOrdersUpdateMutation,
  useSellerOrdersDetailQuery,
  useBuyerOrdersDetailQuery,
  useCategoriesQuery,
  useGetCategoryIdQuery,
  useGetRemainingTimeQuery,
} = userApi;
