import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useGetNotificationsCountQuery } from '../store/slice/apiSlice';
import left from '../images/left.svg';
import join from '../images/join.svg';
import qrcode from '../images/qr code.svg';
import list from '../images/list.svg';
import ring from '../images/ring.svg';

function BuyerPage() {
    const location = useLocation();
    const fromNotification = location.state?.fromNotification || false;
    const animationProps = fromNotification
        ? {
            initial: { x: '-100vw' },
            animate: { x: 0 },
            exit: { x: '100vw' },
            transition: { type: 'tween', duration: 0.3 }
          }
        : {};

    const { data: notificationsCount } = useGetNotificationsCountQuery();
    return (
        <motion.div className="bg-[#f5f5f5] w-full max-h-max min-h-screen pb-8 flex flex-col items-center"
            {...animationProps}
            transition={{ type: 'tween', duration: 0.3 }}>
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/home'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">跟團仔</p>
                <div className='w-[50px] relative'>
                    <Link to='/notification'>
                    <img alt='ring' src={ring} className='w-6 h-6 hover:opacity-80' />
                    </Link>
                    {notificationsCount && notificationsCount.data.count !== 0 && <div className='absolute top-[-8px] right-4 text-white bg-[#8CC1EA] rounded-full px-[6px] text-sm font-semibold'>{notificationsCount.data.count}</div>}
                </div>
            </div>
            <motion.div className='flex flex-col p-4 items-center'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}>
                <p className='text-main-black font-semibold text-lg mb-4'>您今天要使用什麼功能？</p>
                <Link to='/purchasecode'>
                    <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}>
                        <img alt='join' src={join} className='mr-3'/>快速入團</motion.button>
                </Link>
                <Link to='/deliverycode'>
                <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.4 }}>
                    <img alt='qrcode' src={qrcode} className='mr-3'/>取貨連結</motion.button>
                </Link>
                <Link to='/purchaselist'>
                    <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md'
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}>
                        <img alt='list' src={list} className='mr-3'/>購買清單</motion.button>
                </Link>
            </motion.div>
        </motion.div>
    )
}

export default BuyerPage;