import { createSlice } from '@reduxjs/toolkit';
import liff from '@line/liff';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    login: {
      phone: '',
      password: '',
      isLoggedIn: false,
      loginType: '',
      isSeller: JSON.parse(localStorage.getItem('login_role') || 'false'),
    },
    register: {
      phone: '',
      password: '',
      name: '',
    },
    token: localStorage.getItem('auth_token') || '',
  },
  reducers: {
    setLoginPhone: (state, action) => {
      state.login.phone = action.payload;
    },
    setLoginPassword: (state, action) => {
      state.login.password = action.payload;
    },
    setLoginType: (state, action) => {
      state.login.loginType = action.payload;
      localStorage.setItem('login_type', action.payload);
      console.log("Login type set:", action.payload);
    },
    setLoginRole: (state, action) => {
      state.login.isSeller = action.payload;
      localStorage.setItem('login_role', action.payload);
      console.log('is Seller:', action.payload);
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('auth_token', state.token)
    },
    login: (state, action) => {
      state.login.isLoggedIn = true;
      // state.login.loginType = action.payload.login_type;
      localStorage.setItem('auth_token', state.token);
      // localStorage.setItem('login_type', action.payload.login_type);
      console.log("Logged in with token:", state.token);
    },
    logout: (state) => {
      state.login.isLoggedIn = false;
      state.login.phone = '';
      state.login.password = '';
      state.login.loginType = '';
      state.login.isSeller = false;
      state.token = '';
      localStorage.clear();
      console.log("User logged out");

      if (liff.isLoggedIn()) {
        liff.logout();
        console.log("LIFF user logged out");
      }
    },
    setRegisterPhone: (state, action) => {
      state.register.phone = action.payload;
    },
    setRegisterPassword: (state, action) => {
      state.register.password = action.payload;
    },
    setRegisterName: (state, action) => {
      state.register.name = action.payload;
    },
    register: (state) => {
      state.login.isLoggedIn = true;
      state.login.loginType = 'phone';
      localStorage.setItem('auth_token', state.token);
      localStorage.setItem('login_role', state.login.isSeller); 
      console.log("Registered and logged in with token and type 'phone':", state.token);
    },
  },
});

// action creators
export const { 
  setLoginPhone, setLoginPassword, setLoginType, login, logout,
  setRegisterPhone, setRegisterPassword, setRegisterName, register,
  setAuthToken, setLoginRole
} = userSlice.actions;

// reducer
export default userSlice.reducer;