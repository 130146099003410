import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { useGetSupplierProductsQuery, useGetCategoryIdQuery } from "../store/slice/apiSlice";
import left from '../images/left.svg';
import ProductItem from '../components/ProductItem';

function WholesalePage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [orderBy, setOrderBy] = useState('desc');
    const [sortBy, setSortBy] = useState('created_at');
    const [selectedMainCategoryId, setSelectedMainCategoryId] = useState(null);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
    const handleOrderChange = (e) => {
        const value = e.target.value.split(',');
        const order = value[0].split(':')[1];
        const sort = value[1].split(':')[1];

        setOrderBy(order);
        setSortBy(sort);
    };
    const { data } = useGetSupplierProductsQuery({
        product_name: searchTerm,
        sort_by: sortBy,
        order_by: orderBy,
        page: currentPage,
        page_size: 8,
        ...(selectedMainCategoryId ? { main_category_id: selectedMainCategoryId } : {}),
        ...(selectedSubCategoryId ? { sub_category_id: selectedSubCategoryId } : {}),
    });
    const { data: categoryData } = useGetCategoryIdQuery(); 

    const handleMainCategoryChange = (event) => {
        setSelectedMainCategoryId(Number(event.target.value));
        setSelectedSubCategoryId(null);
    };

    const handleSubCategoryChange = (event) => {
        setSelectedSubCategoryId(Number(event.target.value));
      };

    const filteredSubCategories = categoryData?.data.find(category => category.main_category_id === selectedMainCategoryId)?.sub_categories || [];

    useEffect(() => {
        if (data && data.total_pages) {
            setMaxPage(data.total_pages || 1);
        } else {
            setMaxPage(1);
        }
        console.log(data);
    }, [data]);

    useEffect(() => {
        const savedPage = localStorage.getItem('currentPage');
        if (savedPage) {
            setCurrentPage(Number(savedPage));
        }
    }, []);
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('currentPage', page);
    };

    const getPageNumbers = () => {
        let pages = [];
        let startPage, endPage;
    
        if (maxPage <= 4) {
            startPage = 1;
            endPage = maxPage;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= maxPage) {
                startPage = maxPage - 3;
                endPage = maxPage;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }
    
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div className="bg-[#f5f5f5] w-full min-h-screen max-h-max flex flex-col items-center">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/home'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">批貨商品頁</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <motion.div className='flex flex-col p-4 items-center'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}>
                <div className='flex mb-4'>
                    <input placeholder='輸入商品名稱' className='w-[167.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black search
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} />
                    <select className='w-[167.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black appearance-none arrowdown ml-4
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        value={`orderby:${orderBy}, sortby:${sortBy}`}
                        onChange={handleOrderChange}>
                        <option value='orderby:desc, sortby:created_at'>排序：最新</option>
                        <option value='orderby:asc, sortby:created_at'>排序：最舊</option>
                        <option value='orderby:desc, sortby:price'>排序：價格最高</option>
                        <option value='orderby:asc, sortby:price'>排序：價格最低</option>
                    </select>
                </div>
                <div className='flex mb-4'>
                    <select className='w-[167.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black appearance-none arrowdown
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        onChange={handleMainCategoryChange}>
                        <option>主類別</option>
                        {categoryData && categoryData.data.map((category) => (
                            <option key={category.main_category_id} value={category.main_category_id}>{category.main_category_name}</option>
                        ))}
                    </select>
                    <select className='w-[167.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black appearance-none arrowdown ml-4
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        onChange={handleSubCategoryChange}
                        disabled={!selectedMainCategoryId}>
                        <option>次類別</option>
                        {filteredSubCategories.map((subCategory) => (
                            <option key={subCategory.sub_category_id} value={subCategory.sub_category_id}>{subCategory.sub_category_name}</option>
                        ))}
                    </select>
                </div>
                <div className='min-h-[516px]'>
                {data && data.data.map((product) => (
                    <Link key={product.id} to={`/product/${product.id}`}>
                    <ProductItem product={product} />
                    </Link>
                ))}
                </div>
            </motion.div>
            <div className='flex justify-end mt-10 mb-20 pb-20'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer'>&lt;</button>
                {currentPage > 2 && maxPage > 5 && (
                <>
                    <button onClick={() => handlePageChange(1)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>1</button>
                    {currentPage > 3 && <span className='mr-3'>...</span>}
                </>
            )}
            {getPageNumbers().map((page) => (
                <button key={page} onClick={() => handlePageChange(page)} className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}>
                    {page}
                </button>
            ))}
            {currentPage < (maxPage - 2) && maxPage >= 6 && (
                <>
                    {currentPage < (maxPage - 2) && <span className='mr-3'>...</span>}
                    <button onClick={() => handlePageChange(maxPage)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>{maxPage}</button>
                </>
            )}
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === maxPage} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer'>&gt;</button>
            </div>  
        </div>
    )
}

export default WholesalePage;