import { useState } from 'react';
import { motion } from 'framer-motion';
import logo from '../images/logo.svg';
import Login from '../components/Login';
import Register from '../components/Register';

function LoginPage() {
    const [active, setActive] = useState(true);

    return (
        <motion.div className="flex flex-col w-full min-h-screen"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }} >
            <div className='w-full h-auto flex flex-col justify-center items-center'>
                <motion.img alt='logo' src={logo} 
                    className='my-6 w-[100px] h-[40px] mt-16'
                    initial={{ y: 200 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 1 }}
                    />
                <div className='flex w-full border-b-2 border-[#eeeeee]'>
                    <div className='w-[50%] flex flex-col items-center'>
                        <div className={`text-xl font-semibold cursor-pointer mb-1 hover:opacity-90 ${active ? 'text-main-brown' : 'text-[#858585]'}`}
                            onClick={() => setActive(true)}>
                                登入
                        </div>
                        <div className={`w-[40%] h-[5px] top-radious bg-main-brown ${active ? 'flex' : 'hidden'}`}></div>
                    </div>
                    <div className='w-[50%] flex flex-col items-center'>
                        <div className={`text-xl font-semibold cursor-pointer mb-1 hover:opacity-90 ${active ? 'text-[#858585]' : 'text-main-brown'}`}
                            onClick={() => setActive(false)}>
                                註冊
                        </div>
                        <div className={`w-[40%] h-[5px] top-radious bg-main-brown ${active ? 'hidden' : 'flex'}`}></div>
                    </div>
                </div>
            </div>
            {active ? <Login /> : <Register />}      
            <div className='flex flex-col w-full h-full justify-end items-center mt-20'>
                <p className='text-xs text-[#969daa] mb-2'>2024 © 帳房先生 || 正啟科技股份有限公司</p>
                <p className='text-xs text-[#969daa] mb-20'>聯繫請洽Line小編 搜尋：@373gznkg (記得要加@喔)</p>
            </div>
        </motion.div>
    )
}

export default LoginPage;