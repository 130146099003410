import { Link } from 'react-router-dom';
import left from '../images/left.svg';
import search from '../images/search_1.svg';
import { useLazySearchCampaignsQuery } from '../store/slice/apiSlice';
import { useState } from 'react';
import { motion } from 'framer-motion';
import emptysearch from '../images/emptysearch.svg';

function PurchaseCodePage() {
  const [value, setValue] = useState('');
  const [searchCampaigns, { data }] = useLazySearchCampaignsQuery();
  const [result, setResult] = useState('');
  const [result2, setResult2] = useState('');
  const [empty, setEmpty] = useState(false);

  const handleSearchTerm = async () => {
    setEmpty(false);
    try {
      const result = await searchCampaigns({ campaignCode: value }).unwrap();

      setResult(`團購名稱：${result.data.name}`);
      setResult2(`備註：${result.data.note}`);
      localStorage.setItem('campaign_code', value.trim());
      localStorage.setItem('campaign_id', result.data.id);
    } catch (error) {
      console.error('Failed to search campaigns:', error);
      if (error.status === 401) {
        setResult('此功能需登入方可使用');
        setEmpty(true);
      } else if (error.status === 404) {
        setResult('未找到匹配的團購代碼(大小寫需完全符合)');
        setEmpty(true);
      } else {
        setResult('發生未知錯誤，請稍後再試');
        setEmpty(true);
      }
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchTerm();
    }
  };

  return (
    <div className="bg-[#f5f5f5] w-full h-screen flex flex-col items-center">
      <div className="bg-white w-full h-[72px] flex justify-between items-center">
        <Link to="/buyer">
          <img alt="left" src={left} className="pl-4" />
        </Link>
        <p className="text-xl font-semibold text-main-black">快速入團</p>
        <div className="w-[50px] pr-4"></div>
      </div>
      <motion.div
        className="w-[343px] h-auto bg-white rounded-lg shadow-md py-6 px-4 mt-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
        <p className="text-main-black text-lg font-semibold mb-2">團購代碼</p>
        <div className="relative">
          <input
            placeholder="請輸入團購代碼 e.g.123a"
            className="w-[311px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]"
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <img
            alt="search"
            src={search}
            className="absolute top-[10px] right-3 cursor-pointer"
            onClick={handleSearchTerm}
          />
        </div>
        <p className="mt-4">{result}</p>
        <p className="mt-4">{result2}</p>
      </motion.div>
      {empty && (
        <div className="flex flex-col items-center min-h-[300px] justify-center">
          <img alt="empty" src={emptysearch} />
          <p className="text-[#858585] mt-4">抱歉，未找到符合條件的結果</p>
        </div>
      )}
      <div className="bg-white w-full h-[113px] flex justify-center items-center fixed bottom-0 shadow-up z-10">
        {data && (
          <Link to={`/purchase/${data.data.id}`}>
            <button className="w-[343px] h-[50px] text-xl font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown">
              前往商店
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default PurchaseCodePage;
