import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetCampaignsQuery, useGetCampaignsDetailQuery, useCampaignStatusUpdateMutation, useGetCampaignsNameQuery } from '../store/slice/apiSlice';
import { motion } from 'framer-motion';
import left from '../images/left.svg';
import check from '../images/check.svg';
import xmark from '../images/xmark.svg';
import success from '../images/success.svg';
import change from '../images/change.svg';
import nopicture from '../images/nopicture.svg';
import emptybox from '../images/emptybox.svg';

function ManageCampaignPage() {            
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [status, setStatus] = useState('開團中');
    const [campaignId, setCampaignId] = useState(0);
    const [statusCampaignId, setStatusCampaignId] = useState(0);
    
    const { data: campaignsName } = useGetCampaignsNameQuery({ campaign_status: status });

    const handleCampaignChange = (event) => {
        const selectedCampaignId = event.target.value;
        if (selectedCampaignId === '') {
            setCampaignId(0);
        } else {
            setCampaignId(selectedCampaignId);
        }
    };  
    
    useEffect(() => {
        console.log(campaignId);
        setCurrentPage(1);
    }, [campaignId])  

    const { data: campaignsData } = useGetCampaignsQuery({
        campaign_status: status,
        campaign_id: campaignId,
        page: currentPage,
        page_size: 2
    });

    useEffect(() => {
        if (campaignsData && campaignsData.total_pages) {
            setMaxPage(campaignsData.total_pages || 1);
        } else {
            setMaxPage(1);
        }
        console.log(campaignsData);
    }, [campaignsData]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getPageNumbers = () => {
        let pages = [];
        let startPage, endPage;
    
        if (maxPage <= 4) {
            startPage = 1;
            endPage = maxPage;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= maxPage) {
                startPage = maxPage - 3;
                endPage = maxPage;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }
    
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    useEffect(() => {
        setCurrentPage(1);
        setCampaignId(0);
    }, [status]);

    const [showState, setShowState] = useState(false);
    const { data:campaignStatus } = useGetCampaignsDetailQuery(statusCampaignId);

    const handleStatusChange = (campaignId) => {
        setStatusCampaignId(campaignId);
        setShowState(true);
        console.log(campaignStatus);
    };    

    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        if(campaignStatus) {
            setSelectedStatus(campaignStatus.data.status);
        }
    }, [campaignStatus]);

    const handleChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const [statusSuccess, setStatusSuccess] = useState(false);
    const [updateStatus] = useCampaignStatusUpdateMutation();

    const handleStatusUpdate = async () => {
        try {
            const status = { status: selectedStatus };
            const campaign_id = parseInt(statusCampaignId);
            const result = await updateStatus({campaign_id, status}).unwrap();
            console.log(result);
            setShowState(false);
            setStatusSuccess(true);

            setTimeout(() => {
                setStatusSuccess(false);
            }, 1500); 
        } catch (error) {
            console.error('Failed to update status', error);
        }
    }

    return (
        <div className="bg-[#f5f5f5] w-full max-h-max min-h-screen flex flex-col items-center">
            {statusSuccess && 
                <div className='z-10 fixed top-[103px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                    <img alt='success' src={success} className='py-4' />
                    <p className='text-[#45a5de] font-semibold text-2xl pl-2'>狀態已變更</p>
                </div>
            }
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/seller'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">後台管理</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='flex w-full border-b-2 border-[#eeeeee] bg-white'>
                <div className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-main-brown'>團購管理</div>
                    <div className='w-[40%] h-[5px] top-radious bg-main-brown flex'></div>
                </div>
                <Link to='/manageorder' className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-[#858585]'>訂單管理</div>
                </Link>
                <Link to='/managewholesale' className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-[#858585]'>批貨管理</div>
                </Link>
            </div>
            <div className='flex flex-col w-full h-full items-center justify-between'>
                <div className='flex flex-col'>
                    <div className='flex w-[343px] h-[44px] mt-5'>
                        <button className={`w-1/4 rounded-l-full border-[1px] border-[#ebd9c9] font-semibold flex items-center justify-center pr-1
                            ${status === '開團中' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('開團中')}>
                            {status === '開團中' && <img alt='check' src={check} className='w-5 h-5 mr-[1px] ml-2' />}開團中</button>
                        <button className={`w-1/4 border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                            ${status === '已結單' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已結單')}>
                            {status === '已結單' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已結單</button>
                        <button className={`w-1/4 border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                            ${status === '已到貨' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已到貨')}>
                            {status === '已到貨' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已到貨</button>
                        <button className={`w-1/4 border-[1px] rounded-r-full border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center pr-2
                            ${status === '已完成' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已完成')}>
                            {status === '已完成' && <img alt='check' src={check} className='w-5 h-5 mr-1 ml-[1.5px]' />}已完成</button>
                    </div>
                    <select className='text-main-black w-[343px] h-[44px] rounded-full px-3 my-4 border-[1px] border-[#ebd9c9] appearance-none arrowdown
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        onChange={handleCampaignChange}
                        value={campaignId}>
                            <option value=''>全部的團購</option>
                        {campaignsName && campaignsName.data.map((campaign, index) => (
                            <option key={index} value={campaign.campaign_id}>{campaign.name}</option>
                        ))}
                    </select>
                    <div className='flex flex-col min-h-[456px]'>
                    {campaignsData && campaignsData.data.length > 0 ? campaignsData.data
                        .map((campaign, index) => (
                    <motion.div key={index} className='w-[343px] h-auto bg-white rounded-lg shadow-md px-4 py-2 relative mb-4'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.4 }}>
                        <div className='flex justify-end mb-3'>
                            <Link to={`/duplicatestore/${campaign.campaign_id}`} >
                            <p className='text-main-brown cursor-pointer font-medium'>重複開團</p>
                            </Link>
                            <p className='text-[#d2d2d2] mx-2 text-sm cursor-default'>|</p>
                            {status === '已完成' 
                                ? <p className='text-[#D2D2D2] cursor-default'>變更狀態</p>
                                : <p className='text-main-brown cursor-pointer font-medium' 
                                onClick={() => handleStatusChange(campaign.campaign_id)}>變更狀態</p>
                            }
                            <p className='text-[#d2d2d2] mx-2 text-sm cursor-default'>|</p>
                            {status === '已完成'
                                ? <p className='text-[#D2D2D2] cursor-default font-medium'>編輯</p>
                                : <Link to={`/editstore/${campaign.campaign_id}`} >
                            <p className='text-main-brown cursor-pointer font-medium'>編輯</p>
                            </Link>
                            }                            
                        </div>
                        <img alt='campaignImage' src={campaign.image_url === null ? nopicture : campaign.image_url} className='w-16 h-16 rounded-lg mb-3' />
                        <div className='flex justify-between mb-4'>
                            <p className='text-main-black text-lg font-semibold'>團購名稱</p>
                            <p className='text-main-black text-lg font-semibold'>{campaign.name}</p>
                        </div>
                        {campaign.products_sales_details.map((product, index) => (
                            <div key={index} className='flex justify-between mb-2'>
                                <p className='text-main-black leading-[19.2px] w-[150px]'>{product.name}</p>
                                <p className='text-main-black leading-[19.2px] w-[60px] text-end'>x{product.units_sold}</p>
                                <p className='text-main-black leading-[19.2px] w-[100px] text-end'>${product.sales_amount}</p>
                            </div>
                        ))}
                        <div className='flex justify-end mb-2 border-t-2 pt-3'>
                        <p className='text-main-black leading-[19.2px] font-semibold w-[60px] text-end'>x{campaign.total_units_sold}</p>
                            <p className='text-[#e34a4f] leading-[19.2px] font-semibold w-[100px] text-end'>${campaign.total_sales_amount}</p>
                        </div>
                    </motion.div>
                    )) : (
                        <div className='flex flex-col items-center min-h-[500px] justify-center'>
                        <img alt='empty' src={emptybox} />
                        <p className='text-[#858585] mt-4'>還沒有進行中的團購？</p>
                        <p className='text-[#858585]'>讓我們一起創造驚人的銷售奇蹟！</p>
                    </div>
                    )}
                    </div>
                    {showState && campaignStatus &&
                    <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-10" onClick={() => setShowState(false)}>
                        <div className="bg-white p-6 rounded-lg w-[300px] h-auto relative" onClick={(event) => event.stopPropagation()}>
                            <img alt='close' src={xmark} className='absolute top-4 right-4 h-6 w-6 cursor-pointer' 
                                onClick={() => setShowState(false)}/>
                            <img alt='caution' src={change} className='h-10 w-10 mb-4' />
                            <p className='text-main-black text-lg font-semibold mb-3'>狀態變更為</p>
                            <div className='flex items-center mb-4'>
                                <input type='radio' name='state' value='開團中' checked={selectedStatus === '開團中'} onChange={handleChange}/>
                                <p className='text-main-black text-base font-semibold ml-2'>開團中</p>
                                <p className='text-[#858585] text-xs ml-2'>團購開放訂購中</p>
                            </div>
                            <div className='flex items-center mb-4'>
                                <input type='radio' name='state' value='已結單' checked={selectedStatus === '已結單'} onChange={handleChange}/>
                                <p className='text-main-black text-base font-semibold ml-2'>已結單</p>
                                <p className='text-[#858585] text-xs ml-2'>已結束團購</p>
                            </div>
                            <div className='flex items-center mb-4'>
                                <input type='radio' name='state' value='已到貨' checked={selectedStatus === '已到貨'} onChange={handleChange}/>
                                <p className='text-main-black text-base font-semibold ml-2'>已到貨</p>
                                <p className='text-[#858585] text-xs ml-2'>商品已到取貨點</p>
                            </div>
                            {/* <div className='flex items-center mb-6'>
                                <input type='radio' name='state' value='已完成' checked={selectedStatus === '已完成'} onChange={handleChange}/>
                                <p className='text-main-black text-base font-semibold ml-2'>已完成</p>
                                <p className='text-[#858585] text-xs ml-2'>所有商品已交貨完成</p>
                            </div> */}
                            <div className='flex justify-center'>
                                <button className='text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[48px] rounded-full'
                                    onClick={() => setShowState(false)}>取消</button>
                                <button className='text-white font-semibold bg-main-brown w-[190px] h-[48px] rounded-full ml-2'
                                        onClick={handleStatusUpdate}
                                        >確認</button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className='flex justify-end mt-10 mb-20 pb-20'>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer'>&lt;</button>
                    {currentPage > 2 && maxPage > 5 && (
                    <>
                        <button onClick={() => handlePageChange(1)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>1</button>
                        {currentPage > 3 && <span className='mr-3'>...</span>}
                    </>
                )}
                {getPageNumbers().map((page) => (
                    <button key={page} onClick={() => handlePageChange(page)} className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}>
                        {page}
                    </button>
                ))}
                {currentPage < (maxPage - 2) && maxPage >= 6 && (
                    <>
                        {currentPage < (maxPage - 2) && <span className='mr-3'>...</span>}
                        <button onClick={() => handlePageChange(maxPage)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>{maxPage}</button>
                    </>
                )}
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === maxPage} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer'>&gt;</button>
                </div>
            </div>
        </div>
    )
}

export default ManageCampaignPage;