import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import liff from '@line/liff';
import { logout } from '../store/slice/userSlice';
import logo from '../images/logo.svg';
import seller from '../images/seller.svg';
import buyer from '../images/buyer.svg';
import search from '../images/search.svg';
import success from '../images/success.svg';
import disablesearch from '../images/icon-search-grey.svg';
import disableseller from '../images/icon-user-grey.svg'

function HomePage () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDiv, setShowDiv] = useState(false);
    const login = useSelector(state => state.user.login.isLoggedIn);
    const role = useSelector(state => state.user.login.isSeller);

    const handleLogout = () => {
        liff.init({ liffId: '2004697485-WAbN5GgO' })
            .then(() => {
                if (liff.isLoggedIn()) {
                    liff.logout();
                }
                dispatch(logout());
                setShowDiv(true);
    
                setTimeout(() => {
                    setShowDiv(false);
                    navigate('/login');
                }, 1500);
            })
            .catch(error => {
                console.error('LIFF Initialization failed:', error);
            });
    }

    return (
        <div className="bg-[#f5f5f5] w-full max-h-max min-h-screen pb-8 flex flex-col items-center">
            <motion.img alt='logo' src={logo} 
                className='my-6 w-[100px] h-[40px] mt-16'
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}/>
            <p className='text-xl text-main-black font-semibold mb-6'>請選擇您的身份</p>
            <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7 }}>
            {role
            ?
            <Link to='/seller'>
                <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.4 }}>
                    <img alt='seller' src={seller} className='mr-3'/>團購主</motion.button>
            </Link>
            :
            <div className='flex flex-col items-start'>
            <button className='flex text-2xl text-[#c4c4c4] font-semibold items-center justify-center bg-[#f1f2f2] w-[343px] h-[120px] rounded-xl shadow-md mb-1 cursor-not-allowed'>
                <img alt='seller' src={disableseller} className='mr-3'/>團購主
            </button>
            <p className='text-xs text-[#ff5b60] mb-4'>此功能目前僅限受邀會員使用</p>
            </div>
            }
            <Link to='/buyer'>
                <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.4 }}>
                    <img alt='buyer' src={buyer} className='mr-3'/>跟團仔</motion.button>
            </Link>
            {role 
            ? 
            <Link to='/wholesale'>
                <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md'
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.4 }}>
                    <img alt='search' src={search} className='mr-3'/>大盤商品一鍵批發</motion.button>
            </Link>
            :
            <div className='flex flex-col items-start'>
            <button className='flex text-2xl text-[#c4c4c4] font-semibold items-center justify-center bg-[#f1f2f2] w-[343px] h-[120px] rounded-xl shadow-md mb-1 cursor-not-allowed'>
                <img alt='search' src={disablesearch} className='mr-3'/>大盤商品一鍵批發</button>
                <p className='text-xs text-[#ff5b60] mb-4'>此功能目前僅限受邀會員使用</p>
            </div>
            }
            </motion.div>
            {login
                ? <button className="w-[343px] h-[50px] text-base font-semibold text-white bg-main-brown rounded-full mt-[60px] hover:bg-deep-brown"
                    onClick={handleLogout}>登出</button>
                : <Link to='/login'><button className="w-[343px] h-[50px] text-base font-semibold text-white bg-main-brown rounded-full mt-[60px] hover:bg-deep-brown">返回登入頁</button></Link>
            }
            {showDiv && 
                <div className='z-10 fixed top-44 w-full bg-[#e6f4ff] flex justify-center items-center'>
                    <img alt='success' src={success} className='py-4' />
                    <p className='text-[#45a5de] font-semibold text-2xl pl-2'>登出成功</p>
                </div>
            }
        </div>
    )
}

export default HomePage;