import { useGetRemainingTimeQuery } from "../store/slice/apiSlice";
import { motion } from 'framer-motion';
import time from '../images/time.svg';
import nopicture from '../images/nopicture.svg';

function ProductItem({ product }) {
    const { data: endTime } = useGetRemainingTimeQuery(product.end_datetime, {
        pollingInterval: 1000
    });    

    const days = Math.floor((endTime / (1000 * 60 * 60 * 24)));
    const hours = Math.floor((endTime / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((endTime / (1000 * 60)) % 60);
    const seconds = Math.floor((endTime / 1000) % 60);

    return (
        <motion.div className="flex w-[375px] h-[156px] bg-white mb-4 shadow-md py-2 px-4"
        whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.4 }}>
            <img alt='product' src={product.images && product.images.length > 0 ? product.images[0] : nopicture} className="w-[140px] h-[140px] rounded" />
            <div className="flex flex-col ml-2 justify-between">
                <p className="text-lg font-semibold text-main-black">{product.name}</p>
                <div className="flex flex-col justify-end items-end w-[195px]">
                    <p className="text-lg font-semibold text-[#e34a4f]">${product.discounted_price ? product.discounted_price : product.origin_price}</p>
                    <div className="flex my-1">
                        <p className="text-xs text-main-brown ml-1 font-mono">最少購買{product.MOQ}件</p>
                        <p className="text-[#d2d2d2] text-xs mx-2">|</p>
                        <p className="text-xs text-main-brown">熱銷倒數{product.stock_amount}件</p>
                    </div>
                    <div className="flex">
                        <img alt='time' src={time} className="w-[13px]" />
                        <p className="text-xs text-main-brown ml-1 font-mono">{`倒數${days || 0}天${hours || 0}時${minutes || 0}分${seconds || 0}秒`}</p>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default ProductItem;