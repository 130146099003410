import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useGetNotificationsQuery } from '../store/slice/apiSlice';
import left from '../images/left.svg';
import notification from '../images/notification.svg';
import unread from '../images/unread.svg';

function NotificationPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const { data: notifications } = useGetNotificationsQuery({
        page: currentPage,
        page_size: 5,
    });
    useEffect(() => {
        if (notifications && notifications.total_pages) {
            setMaxPage(notifications.total_pages || 1);
        } else {
            setMaxPage(1);
        }
        console.log(notifications);
    }, [notifications]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getPageNumbers = () => {
        let pages = [];
        let startPage, endPage;
    
        if (maxPage <= 4) {
            startPage = 1;
            endPage = maxPage;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= maxPage) {
                startPage = maxPage - 3;
                endPage = maxPage;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }
    
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };
    return (
        <motion.div className="bg-[#f5f5f5] w-full max-h-max min-h-screen pb-8 flex flex-col items-center"
            initial={{ x: '100vw' }}
            animate={{ x: 0 }}
            exit={{ x: '-100vw' }}
            transition={{ type: 'tween', duration: 0.3 }}>
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to="/buyer" state={{ fromNotification: true }}>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">通知</p>
                <div className='w-[70px]'></div>
            </div>
            <motion.div className='flex flex-col p-4 min-h-[652px] items-center'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}>
                {notifications && notifications.data.length > 0 ? notifications.data.map((notifi) => (
                <div key={notifi.notification_id} className='flex flex-col bg-white rounded-xl shadow-md px-4 py-2 my-2 w-[343px] h-[108px]'>
                    <div className='flex items-center'>
                        <img alt='notification' src={notifi.is_read ? notification : unread} className='w-13 h-13 mr-2' />
                        <p className='text-main-black text-lg'>團購<span className='font-semibold mx-1'>{notifi.campaign_name}</span>已到貨</p>
                    </div>
                    <div className='flex justify-end'>
                        <p className='text-main-brown bg-[#F4ECDD] rounded-full px-3 py-1 font-mono'>{notifi.created_at}</p>
                    </div>
                </div>
                )) : <p className='text-main-black'>目前沒有通知</p>}
            </motion.div>
            <div className='flex justify-end mt-4 mb-20 pb-20'>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer'>&lt;</button>
                    {currentPage > 2 && maxPage > 5 && (
                    <>
                        <button onClick={() => handlePageChange(1)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>1</button>
                        {currentPage > 3 && <span className='mr-3'>...</span>}
                    </>
                )}
                {getPageNumbers().map((page) => (
                    <button key={page} onClick={() => handlePageChange(page)} className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}>
                        {page}
                    </button>
                ))}
                {currentPage < (maxPage - 2) && maxPage >= 6 && (
                    <>
                        {currentPage < (maxPage - 2) && <span className='mr-3'>...</span>}
                        <button onClick={() => handlePageChange(maxPage)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>{maxPage}</button>
                    </>
                )}
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === maxPage} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer'>&gt;</button>
                </div>
        </motion.div>
    );
    }

export default NotificationPage;