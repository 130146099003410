import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSendSmsMutation, useResetPasswordMutation } from "../store/slice/apiSlice";
import left from '../images/left.svg';
import success from '../images/success.svg';

function ForgetPasswordPage ()  {
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        setPhone(newPhone);
        if (!/^09\d{8}$/.test(newPhone)) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    }
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (/^[A-Za-z0-9]{8,16}$/.test(newPassword)) {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }
    }
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    const [verifycode, setVerifycode] = useState('')
    const handleVerifycodeChange = (e) => {
        setVerifycode(e.target.value);
    }

    const [registerError, setRegisterError] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [sendSms] = useSendSmsMutation();
    const [resetPassword] = useResetPasswordMutation();
    const navigate = useNavigate();
    const [showDiv, setShowDiv] = useState(false);

    const handleSendSms = async () => {
        setIsButtonDisabled(true);
        setCountdown(60);

        try {
          const result = await sendSms({ phone, is_forget_passwor: true }).unwrap();
          console.log('SMS sent successfully', result);
        } catch (error) {
            if (error.status === 400 && error.data.detail.includes('User already verified')) {
                setRegisterError('此手機號碼已註冊，請直接登入')
            } else {
                setRegisterError('請檢查網路連線')
            }
          console.error('Failed to send SMS:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown > 0) {
                        return prevCountdown - 1;
                    }
                    clearInterval(interval);
                    setIsButtonDisabled(false);
                    return 0;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const handleReset = async () => {
        try {
            const user = {
                phone,
                sms_code:verifycode,
                new_password: password
            }
            const result = await resetPassword(user).unwrap();
            console.log(result);

            setShowDiv(true);
    
            setTimeout(() => {
                setShowDiv(false);
                navigate('/login');
            }, 1500); 
        } catch (error) {
            if (error.data.detail.includes('Wrong SMS code')) {
                setRegisterError('驗證碼錯誤，請重新輸入');
            } else if (error.data.detail.includes('SMS code expired')) {
                setRegisterError('驗證碼已過期，請重新獲取驗證碼');
            }
          console.error('Failed to reset password:', error);
        }
    }

    return (
        <div className="bg-[#f5f5f5] w-full h-screen flex flex-col items-center">
            {showDiv && 
                <div className='z-10 fixed top-[72px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                    <img alt='success' src={success} className='py-4' />
                    <p className='text-[#45a5de] font-semibold text-2xl pl-2'>密碼更新完成</p>
                </div>
            }
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/login'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">重設密碼</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='w-[343px] h-auto bg-white rounded-lg shadow-md py-6 px-4 mt-4'>
                <div className='relative'>
                <input placeholder='請輸入手機號碼'
                        className={`w-[311px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 mb-6 login-phone
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                        ${phoneError ? 'border-2 border-[#ff5b60]' : ''}`}
                        value={phone}
                        onChange={handlePhoneChange}></input>
                        {phoneError ? <div className='text-[#ff5b60] text-xs absolute top-[54px] left-[10px]'>手機號碼應為10個數字</div> : ''}
                </div>
                <div className='flex w-[343px] relative'>
                    <input placeholder='請輸入驗證碼'
                        className='w-[194spx] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-6 mb-6
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        value={verifycode}
                        onChange={handleVerifycodeChange}></input>
                    {registerError && <p className="text-[#ff5b60] text-xs absolute top-[56px] left-[10px]">{registerError}</p>}
                    <button className={`text-white text-sm font-semibold py-4 h-[52px] ml-2 w-[109px] rounded-full
                        ${isButtonDisabled ? 'bg-[#cdcdcd]' : 'bg-main-brown hover:bg-deep-brown'}`}
                        disabled={isButtonDisabled}
                        onClick={handleSendSms}>取得驗證碼</button>
                        {isButtonDisabled && <p className="absolute top-[56px] right-12 text-xs text-[#cdcdcd]">{`請等待 ${countdown} 秒`}</p>}
                </div>
                <div className='relative w-[311px] mb-4'>
                    <input placeholder='請輸入新密碼'
                        className={`w-[311px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 login-password
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                        ${passwordError ? 'border-2 border-[#ff5b60]' : ''}`}
                        value={password}
                        type={passwordShown ? 'text' : 'password'}
                        onChange={handlePasswordChange}></input>
                    {passwordShown ? <div className="eye-slash-icon eye-icon-all text-main-brown" onClick={togglePasswordVisibility}></div> : <div className="eye-icon eye-icon-all text-main-brown" onClick={togglePasswordVisibility}></div>}
                    {passwordError ? <div className='text-[#ff5b60] text-xs absolute top-[55px] left-[10px]'>密碼應包含英文數字共8-16個字元</div> : ''}
                </div>
            </div>
            <div className="bg-white w-full h-[113px] flex justify-center items-center fixed bottom-0 shadow-up z-10">
                <button className="w-[343px] h-[50px] text-xl font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown"
                onClick={handleReset}
                    >確認</button>
            </div>
        </div>
    )
}

export default ForgetPasswordPage;