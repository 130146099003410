import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import {
  useGetSupplierProductsDetailQuery,
  useGetRemainingTimeQuery,
  useCreateSupplierOrdersMutation,
  useUserInfoQuery,
} from '../store/slice/apiSlice';
import left from '../images/left.svg';
import time from '../images/time.svg';

function ProductPage() {
  const { productId } = useParams();
  const { data } = useGetSupplierProductsDetailQuery(parseInt(productId));
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (data) {
      const formattedDescription = data.data.description
        .replaceAll('\r\n', '<br>')
        .replaceAll('\n', '<br>')
        .replaceAll('\r', '<br>');
      setDescription(formattedDescription);
    }
  }, [data]);

  const { data: endTime } = useGetRemainingTimeQuery(
    data && data.data.end_datetime,
    {
      pollingInterval: 1000, // 確保在 hook 調用時設置了輪詢間隔
    }
  );

  const days = Math.floor(endTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((endTime / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((endTime / (1000 * 60)) % 60);
  const seconds = Math.floor((endTime / 1000) % 60);

  const [showDiv, setShowDiv] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const navigate = useNavigate();

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const { data: userInfo } = useUserInfoQuery();

  useEffect(() => {
    if (userInfo) {
      setPhone(userInfo.phone);
      setAddress(userInfo.address);
    }
  }, [userInfo]);

  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [createSupplierOrders] = useCreateSupplierOrdersMutation();
  const [error, setError] = useState('');

  const handleRadioChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePurchase = async () => {
    if (!showDiv) {
      setShowDiv(true);
      return;
    }
    if (data && quantity % data.data.MOQ !== 0) {
      setError(`購買數量需 至少${data.data.MOQ} 且為 ${data.data.MOQ}的倍數`);
      return;
    }

    try {
      const formData = {
        supplier_product_id: data.data.id,
        quantity,
        price_at_order: data.data.discounted_price
          ? data.data.discounted_price
          : data.data.origin_price,
        address,
        phone,
        save_address: isChecked,
      };

      const result = await createSupplierOrders(formData).unwrap();
      console.log(result);
      localStorage.setItem('supplier_order_id', result.data.supplier_order_id);

      navigate('/wholesalesuccess', { state: { productId } });
    } catch (error) {
      console.error('批發失敗', error);
      if (error.data.detail.includes('order quantity is less than MOQ')) {
        setError('購買數量需大於最少購買量');
      }
    }
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleInputChange = (value) => {
    const newValue = parseInt(value, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      setQuantity(newValue);
    } else {
      setQuantity(0);
    }
  };

  return (
    <div className="bg-[#f5f5f5] w-full min-h-screen flex flex-col items-center">
      <div className="bg-white w-full h-[72px] flex justify-between items-center">
        <Link to="/wholesale">
          <img alt="left" src={left} className="pl-4" />
        </Link>
        <p className="text-xl font-semibold text-main-black">批貨商品頁</p>
        <div className="w-[50px] pr-4"></div>
      </div>
      {data && (
        <div className="w-[375px] h-auto flex flex-col">
          <div>
            <Swiper
              style={{
                '--swiper-navigation-color': '#ccc',
                '--swiper-pagination-color': '#ccc',
              }}
              loop={true}
              spaceBetween={0}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="h-[390px] w-full bg-white select-none"
            >
              {data.data.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    alt="option"
                    src={image}
                    className="w-full h-full object-cover"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={1}
              slidesPerView={3.2}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="h-[120px] w-full pl-3 pt-[10px] bg-white mb-2 shadow-md"
            >
              {data.data.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    alt="option"
                    src={image}
                    className="w-[100px] h-[100px] rounded-md cursor-pointer"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="w-full h-auto bg-white shadow-md flex flex-col pt-2 pb-3 px-4 mb-2">
            <p className="text-lg font-semibold text-main-black">
              {data.data.name}
            </p>
            <div className="flex items-end mt-4">
              <p className="text-main-black font-semibold">平台批發價：</p>
              {data.data.discounted_price ? (
                <>
                  <p className="text-xs font-semibold text-main-black line-through mb-1">
                    ${data.data.origin_price}
                  </p>
                  <p className="text-xl font-semibold text-[#e34a4f]">
                    ${data.data.discounted_price}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-xl font-semibold text-[#e34a4f]">
                    ${data.data.origin_price}
                  </p>
                </>
              )}
            </div>
            <p className="text-[#ff5b60] text-xs mt-3">
              建議售價：${data.data.recommended_price}
            </p>
            <div className="flex mt-4">
              <p className="text-xs text-main-brown">
                最少購買{data.data.MOQ}件
              </p>
              <p className="text-[#d2d2d2] text-xs mx-1">|</p>
              <p className="text-xs text-main-brown">
                熱銷倒數{data.data.stock_amount}件
              </p>
              <p className="text-[#d2d2d2] text-xs mx-1">|</p>
              <img alt="time" src={time} />
              <p className="text-xs text-main-brown ml-[1px] font-mono">{`倒數${days || 0}天${hours || 0}時${minutes || 0}分${seconds || 0}秒`}</p>
            </div>
          </div>
          <div className="w-full h-auto bg-white shadow-md flex flex-col pt-3 pb-8 px-4 mb-36">
            <p className="text-base font-semibold text-main-black mb-4">
              詳細說明
            </p>
            <p
              className="text-main-black mb-4"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
          <div className="flex h-10 w-full"></div>
        </div>
      )}
      {showDiv && data && (
        <div
          className="fixed inset-0 bg-black/60 flex items-end pb-[80px] z-10"
          onClick={() => setShowDiv(false)}
        >
          <div
            className="flex justify-center bg-white w-full h-auto py-2 px-4 z-20"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="flex flex-col w-[375px]">
              <div className="flex w-[343px] border-b-[1px] border-[#eeeeee] py-4">
                <img
                  alt="product"
                  src={data.data.images[0]}
                  className="w-[110px] h-[110px] rounded-lg"
                />
                <div className="flex flex-col justify-end ml-2">
                  <div className="flex items-end">
                    <p className="text-xs font-semibold text-main-black line-through mb-1">
                      ${data.data.origin_price}
                    </p>
                    <p className="text-xl font-semibold text-[#e34a4f]">
                      ${data.data.discounted_price}
                    </p>
                  </div>
                  <div className="flex mt-2">
                    <p className="text-xs text-main-brown">
                      最少購買{data.data.MOQ}件
                    </p>
                    <p className="text-[#d2d2d2] text-xs mx-2">|</p>
                    <p className="text-xs text-main-brown">
                      熱銷倒數{data.data.stock_amount}件
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex w-[343px] border-b-[1px] border-[#eeeeee] py-4">
                <div className="flex flex-col w-full">
                  <p className="text-xl text-main-black font-semibold my-2">
                    手機號碼
                  </p>
                  <div className="flex my-2">
                    <input
                      placeholder="請輸入手機號碼"
                      className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 mb-2 login-phone
                                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]`}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></input>
                  </div>
                  <p className="text-xl text-main-black font-semibold my-2">
                    收件地址
                  </p>
                  <div className="flex my-2">
                    <input
                      placeholder="請輸入收件地址"
                      className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 mb-2 address
                                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                                    `}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></input>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="time"
                      className="accent-main-brown w-4 h-4"
                      onChange={handleRadioChange}
                      checked={isChecked}
                    />
                    <div className="flex justify-between w-full">
                      <p className="text-main-black font-medium ml-2">記住我</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[343px] border-b-[1px] border-[#eeeeee] py-4 pb-16 relative">
                <div className="flex justify-between w-full">
                  <p className="text-xl text-main-black font-semibold my-2">
                    數量
                  </p>
                  <div className="flex justify-center items-center">
                    <button
                      className={`text-gray-pr bg-[#f8f8f8] text-3xl w-10 h-10 flex justify-center items-center rounded-full pb-1 ${quantity === 1 ? 'text-[#adadad]' : ''}`}
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      className="text-xl text-main-brown font-bold w-[90px] h-11 rounded-full border-2 border-[#ebd9c9] mx-4 text-center
                                focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]"
                      value={quantity}
                      onChange={(e) => handleInputChange(e.target.value)}
                      min="1"
                    />
                    <button
                      className="text-gray-pr bg-[#f8f8f8] text-3xl w-10 h-10 flex justify-center items-center rounded-full pb-1"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                </div>
                {error && (
                  <p className="text-[#ff5b60] text-xs absolute top-[68px] left-0">
                    {error}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white w-full h-auto flex flex-col justify-center items-center fixed bottom-0 shadow-up z-10">
        <Link to={`/wholesalestore/${data && data.data.id}`}>
          <button
            className={`${showDiv ? 'hidden' : 'flex justify-center items-center'} w-[343px] h-[50px] mt-4 text-xl font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown`}
          >
            快速開團
          </button>
        </Link>
        <button
          className={`${showDiv ? 'bg-main-brown text-white hover:bg-deep-brown' : 'text-main-brown border-main-brown border-[3px] hover:text-deep-brown hover:border-deep-brown'} w-[343px] h-[50px] my-4 text-xl font-semibold  rounded-full`}
          onClick={handlePurchase}
        >
          {showDiv ? '確認下單' : '批貨下單'}
        </button>
      </div>
    </div>
  );
}

export default ProductPage;
