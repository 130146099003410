import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetOrdersQuery, useBuyerOrdersDetailQuery, useDeleteOrdersMutation } from '../store/slice/apiSlice';
import TruncatedText from '../components/TruncateText'; 
import { motion } from 'framer-motion';
import left from '../images/left.svg';
import check from '../images/check.svg';
import xmark from '../images/xmark.svg';
import caution from '../images/caution.svg';
import success from '../images/success.svg';
import emptybag from '../images/emptybag.svg';

function PurchaseListPage() {
    const [status, setStatus] = useState('開團中');
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    
    const { data } = useGetOrdersQuery({
        order_status: status,
        page: currentPage,
        page_size: 6
    });

    const getPageNumbers = () => {
        let pages = [];
        let startPage, endPage;
    
        if (maxPage <= 4) {
            startPage = 1;
            endPage = maxPage;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= maxPage) {
                startPage = maxPage - 3;
                endPage = maxPage;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }
    
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [status]);

    useEffect(() => {
        if (data && data.total_pages) {
            setMaxPage(data.total_pages || 1);
        } else {
            setMaxPage(1);
        }
    }, [data]);

    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const orderDetail = useBuyerOrdersDetailQuery(selectedOrderId, { skip: !selectedOrderId });

    const handleOrderClick = (orderId) => {
        setSelectedOrderId(orderId);
    };

    useEffect(() => {
        console.log(selectedOrderId);
        console.log(orderDetail);
    }, [selectedOrderId, orderDetail]);

    const [showDelete, setShowDelete] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [deleteOrder] = useDeleteOrdersMutation();
    const handleDeleteOrder = async () => {
        try {
            const result = await deleteOrder(selectedOrderId).unwrap();
            console.log(result);

            setShowDelete(false);
            setSelectedOrderId(null);
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
            }, 1500);
        } catch (error) {
            console.error('Failed to delete order', error);
        }
    }

    return (
        <div className="bg-[#f5f5f5] w-full max-h-max min-h-screen flex flex-col items-center">
            {showSuccess && 
                <div className='z-10 fixed top-[72px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                    <img alt='success' src={success} className='py-4' />
                    <p className='text-[#45a5de] font-semibold text-2xl pl-2'>取消訂單</p>
                </div>
            }
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/buyer'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">購買清單</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='flex w-[343px] h-[44px] my-4'>
                <button className={`w-1/4 rounded-l-full border-[1px] border-[#ebd9c9] font-semibold flex items-center justify-center pr-1
                    ${status === '開團中' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('開團中')}>
                    {status === '開團中' && <img alt='check' src={check} className='w-5 h-5 mr-[1px] ml-2' />}開團中</button>
                <button className={`w-1/4 border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                    ${status === '已結單' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已結單')}>
                    {status === '已結單' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已結單</button>
                <button className={`w-1/4 border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                    ${status === '已到貨' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已到貨')}>
                    {status === '已到貨' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已到貨</button>
                <button className={`w-1/4 border-[1px] rounded-r-full border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center pr-2
                    ${status === '已完成' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已完成')}>
                    {status === '已完成' && <img alt='check' src={check} className='w-5 h-5 mr-1 ml-[1.5px]' />}已完成</button>
            </div>
            
            {data && data.data.length > 0 ? data.data.map((item, index) => (
                <motion.div key={index} className='w-[343px] h-[60px] bg-white rounded-lg flex items-center p-2 mb-4 justify-between shadow-md cursor-pointer hover:opacity-85'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                    onClick={() => handleOrderClick(item.order_id)}>
                    <p className='text-main-black w-[100px] ml-2'>
                        <TruncatedText text={item.seller_name} maxLength={9} />
                    </p>
                    <p className='text-main-black w-[67px]'>{item.order_id}</p>
                    <p className='text-[#858585] w-[160px] mr-2'>
                        <TruncatedText text={item.campaign_name} maxLength={18} />
                    </p>
                </motion.div>
            )) : (<div className='flex flex-col items-center min-h-[600px] justify-center'>
                <img alt='empty' src={emptybag} />
                <p className='text-[#858585] mt-4'>您的購買清單還是空的，</p>
                <p className='text-[#858585]'>期待您開始添加商品！</p>
            </div>)}
            {selectedOrderId && orderDetail.data && (
                <div className="fixed inset-0 bg-black/60 flex justify-center items-center" onClick={() => setSelectedOrderId(null)}>
                    <div className="bg-white p-4 rounded-lg w-[343px] h-auto relative" onClick={(event) => event.stopPropagation()}>
                        <img alt='close' src={xmark} className='absolute top-4 right-4 h-6 w-6 cursor-pointer' 
                            onClick={() => setSelectedOrderId(null)}/>
                        <p className='text-main-black text-lg font-semibold mb-4'>訂單詳情</p>
                        <img alt='product' src={orderDetail.data.data.order_details[0].product_image} className='w-[110px] h-[110px] rounded-lg mb-4' />
                        <div className='flex justify-between'>
                            <p className='text-main-black text-base font-semibold mb-4'>{orderDetail.data.data.campaign_name}</p>
                            <p className='text-main-black text-base font-semibold mb-4'>{orderDetail.data.data.id}</p>
                        </div>
                        {orderDetail.data.data.order_details.map((product, index) => (
                            <div key={index} className='flex justify-between mb-4'>
                                <p className='text-main-black text-base w-[150px]'>{product.product_name}</p>
                                <p className='text-main-black text-base w-12 text-end'>{product.quantity}</p>
                                <p className='text-main-black text-base w-[100px] text-end'>${product.item_subtotal}</p>
                            </div>
                        ))}
                        <div className='flex justify-between border-y-[1px] pt-2 pb-6'>
                            <p className='text-main-black text-base w-[150px]'>總額</p>
                            <p className='text-main-black text-base w-12 text-end'>{orderDetail.data.data.total_items}</p>
                            <p className='text-[#e34a4f] font-semibold text-base w-[100px] text-end'>${orderDetail.data.data.total_amount}</p>
                        </div>
                        <div className='flex justify-between mt-6'>
                            <p className='text-main-black text-base font-semibold mb-3'>開團者</p>
                            <p className='text-main-black'>{orderDetail.data.data.seller_name}</p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-main-black text-base font-semibold mb-6'>取貨點</p>
                            <p className='text-main-black'>{orderDetail.data.data.pickup}</p>
                        </div>
                        <div className='flex justify-center'>
                            <button className='text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[55px] rounded-full'
                                onClick={() => setShowDelete(true)}>取消訂單</button>
                            <button className='text-white font-semibold bg-main-brown w-[190px] h-[55px] rounded-full ml-4'
                                    onClick={() => setSelectedOrderId(null)}>確認</button>
                        </div>
                    </div>
                </div>
            )}
            {showDelete && 
                <div className="fixed inset-0 bg-black/60 flex justify-center items-center" onClick={() => setShowDelete(false)}>
                    <div className="bg-white p-6 rounded-lg w-[300px] h-auto relative" onClick={(event) => event.stopPropagation()}>
                        <img alt='close' src={xmark} className='absolute top-4 right-4 h-6 w-6 cursor-pointer' 
                            onClick={() => setShowDelete(false)}/>
                        <img alt='caution' src={caution} className='h-10 w-10 mb-6' />
                        <p className='text-main-black text-lg font-semibold mb-3'>注意</p>
                        <p className='text-main-black text-base mb-8'>
                            點擊取消訂單，此訂單將更改為<span className='text-main-brown'>不成立</span>狀態，您確定要執行此操作嗎？
                        </p>
                        <div className='flex justify-center'>
                            <button className='text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[48px] rounded-full'
                                onClick={() => setShowDelete(false)}>取消</button>
                            <button className='text-white font-semibold bg-main-brown w-[190px] h-[48px] rounded-full ml-2'
                                    onClick={handleDeleteOrder}>確定取消訂單</button>
                        </div>
                    </div>
                </div>
            }
            <div className='flex justify-end mt-10 mb-20 pb-20'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer'>&lt;</button>
                {currentPage > 2 && maxPage > 5 && (
                <>
                    <button onClick={() => handlePageChange(1)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>1</button>
                    {currentPage > 3 && <span className='mr-3'>...</span>}
                </>
            )}
            {getPageNumbers().map((page) => (
                <button key={page} onClick={() => handlePageChange(page)} className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}>
                    {page}
                </button>
            ))}
            {currentPage < (maxPage - 2) && maxPage >= 6 && (
                <>
                    {currentPage < (maxPage - 2) && <span className='mr-3'>...</span>}
                    <button onClick={() => handlePageChange(maxPage)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>{maxPage}</button>
                </>
            )}
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === maxPage} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer'>&gt;</button>
            </div>
        </div>
    )
}

export default PurchaseListPage;