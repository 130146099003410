import { Link } from 'react-router-dom';
import left from '../images/left.svg';
import { useBuyerQrcodeQuery } from '../store/slice/apiSlice';
import { motion } from 'framer-motion';

function DeliveryCodePage() {
    const { data } = useBuyerQrcodeQuery();

    return (
        <div className="bg-[#f5f5f5] w-full h-screen flex flex-col items-center">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/buyer'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">取貨連結</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='flex flex-col mt-9 items-center'>
                <p className='text-main-black text-lg font-semibold mb-9'>Hi, {data && data.user_name}<br />
                取貨時提供 QR code 給團購主，<br />
                可以加速取貨喔！</p>
                {data && <motion.img alt='qrcode' src={data.buyer_qrcode}
                className='h-[300px] w-[300px]'
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20
                }}/>}
            </div>
        </div>
    )
}

export default DeliveryCodePage;