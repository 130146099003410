import React, { useState, useEffect } from 'react';
import { useCategoriesQuery, useCreateProductsMutation } from '../store/slice/apiSlice';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import left from '../images/left.svg';
import upload from '../images/upload.svg';
import Loading from '../components/Loading';

function CreateProductPage () {
    const { data } = useCategoriesQuery();

    const handleMainCategoryChange = (productId, value) => {
        const updatedProducts = products.map(product => {
            if (product.id === productId) {
                const categoryObject = data.data.find(cat => cat.main_category === value);
                return {
                    ...product,
                    mainCategory: value,
                    subCategories: categoryObject ? categoryObject.sub_categories : [],
                    selectedSubCategory: ''
                };
            }
            return product;
        });
        setProducts(updatedProducts);
    };

    const handleSubCategoryChange = (productId, value) => {
        const updatedProducts = products.map(product => {
            if (product.id === productId) {
                return {
                    ...product,
                    selectedSubCategory: value
                };
            }
            return product;
        });
        setProducts(updatedProducts);
    };

    const handleFileSelect = async (productId, event) => {
        event.preventDefault();
        const newFiles = Array.from(event.target.files);
        const filesWithPreview = await Promise.all(newFiles.map(async (file) => {
            if (file.type.startsWith('image/')) {
                const resizedFile = await resizeImage(file);
                return {
                    file,
                    preview: URL.createObjectURL(resizedFile),
                    id: uuidv4()
                };
            }
            return null;
        }).filter(file => file !== null));
    
        setProducts(prevProducts => prevProducts.map(product => {
            if (product.id === productId) {
                return {
                    ...product,
                    images: [...product.images, ...filesWithPreview]
                };
            }
            return product;
        }));
    };

    const resizeImage = (file) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                const maxDimension = 720;
                let { width, height } = image;
                if (width > maxDimension || height > maxDimension) {
                    const scalingFactor = maxDimension / Math.max(width, height);
                    width = width * scalingFactor;
                    height = height * scalingFactor;
                }
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(new File([blob], file.name, { type: file.type, lastModified: Date.now() }));
                    } else {
                        reject(new Error('Could not resize image'));
                    }
                }, file.type);
            };
            image.onerror = (error) => {
                reject(error);
            };
        });
    };

    const deleteFile = (productId, fileId) => {
        setProducts(prevProducts => prevProducts.map(product => {
            if (product.id === productId) {
                const newImages = product.images.filter(image => {
                    if (image.id !== fileId) return true;
                    URL.revokeObjectURL(image.preview);
                    return false;
                });
                return { ...product, images: newImages };
            }
            return product;
        }));
    };    

    const [products, setProducts] = useState([
        { 
            id: uuidv4(), 
            main_category: '',
            sub_category: '',
            name: '', 
            description: '',
            images: [],
            price: null,
            discounted_price: null,
            MOQ: null,
            stock_amount: null
        }
    ]);

    const addProduct = () => {
        const newProduct = {
            id: uuidv4(),
            main_category: '',
            sub_category: '',
            name: '',
            description: '',
            images: [],
            price: null,
            discounted_price: null,
            MOQ: null,
            stock_amount: null
        };
        setProducts([...products, newProduct]);
    };

    const deleteProduct = (productId) => {
        setProducts(products.filter(product => product.id !== productId));
    };
    
    const [inputError, setInputError] = useState('');
    const handleProductChange = (id, field, value) => {
        if (['price', 'MOQ', 'stock_amount', 'discounted_price'].includes(field)) {
            if (value === '') {
                setProducts(prevProducts => prevProducts.map(product =>
                    product.id === id ? { ...product, [field]: null } : product
                ));
            } else if (/^\d*$/.test(value)) {
                setInputError('');
                setProducts(prevProducts => prevProducts.map(product =>
                    product.id === id ? { ...product, [field]: Number(value) } : product
                ));
            } else {
                setInputError('價格、購買量與庫存僅能輸入半形數字格式');
            }
        } else {
            setProducts(prevProducts => prevProducts.map(product =>
                product.id === id ? { ...product, [field]: value } : product
            ));
        }
    };    

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let filledInputs = 0;
        let totalInputs = 0;
    
        products.forEach(product => {
            totalInputs += 8;
            if (product.mainCategory) filledInputs += 1;
            if (product.selectedSubCategory) filledInputs += 1;
            if (product.name.trim()) filledInputs += 1;
            if (product.description.trim()) filledInputs += 1;
            if (product.images.length > 0) filledInputs += 1;
            if (product.price != null) filledInputs += 1;
            if (product.MOQ != null) filledInputs += 1;
            if (product.stock_amount != null) filledInputs += 1;
        });
    
        const newProgress = (filledInputs / totalInputs) * 100;
        setProgress(Math.round(newProgress));
    }, [products]);
    
    const getCampaignId = localStorage.getItem('campaign_id');
    const [createProduct] = useCreateProductsMutation();
    const navigate = useNavigate();
    const [successfulProductIds, setSuccessfulProductIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCreateProduct = async () => {
        setLoading(true);
        try {
            const productsToCreate = products.filter(product => !successfulProductIds.includes(product.id));

            for(const product of productsToCreate) {
                const formData = new FormData();
                formData.append('request_id', product.id);
                formData.append('name', product.name);
                formData.append('description', product.description);
                formData.append('price', product.price);
                formData.append('discounted_price', product.discounted_price || 0); // if it exists
                formData.append('main_category', product.mainCategory);
                formData.append('sub_category', product.selectedSubCategory);
                formData.append('MOQ', product.MOQ);
                formData.append('stock_amount', product.stock_amount);

                product.images.forEach(imageObject => {
                    formData.append('images', imageObject.file); // Use the file blob
                });

                formData.append('campaign_id', getCampaignId);

                const result = await createProduct(formData).unwrap();
                console.log(result);
                setSuccessfulProductIds(prevIds => [...prevIds, product.id]);
            }
            setLoading(false);
            navigate('/storesuccess');
        } catch (error) {
            if (error.status === 401) {
                setInputError('此功能需登入方可使用');
            } else if (error.status === 403) {
                setInputError('此功能需賣家權限');
            } else if (error.status === 422) {
                setInputError('請檢查是否確實填寫欄位及上傳圖檔');
            } else if (error.status === 400) {
                setInputError('請檢查網路連線');
            }
            setLoading(false);
            console.error('Failed to create products', error);
        }
    }

    return (
        <div className="bg-[#f5f5f5] w-full min-h-screen flex flex-col items-center">
            {loading && <div className='fixed inset-0 flex items-center z-10'><Loading className='' /></div>}
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/store'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">建立商品</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='w-full h-2 bg-[#e3e2e2] sticky top-0 z-10'>
                <div style={{ width: `${progress}%` }} className='h-full bg-[#f2ca7f] transition-width duration-500'></div>
            </div>
            {products.map((product) => (
            <motion.div key={product.id} className='w-[343px] h-auto bg-white rounded-lg p-4 shadow-lg m-4 relative'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}>
                <div className='flex mb-6'>
                    <div className='flex flex-col'>
                        <p className='text-lg font-semibold text-main-black'>主類別</p>
                        <select className='w-[150px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909] appearance-none arrowdown'
                            value={product.mainCategory}
                            onChange={(e) => handleMainCategoryChange(product.id, e.target.value)}
                        >
                            <option value="">選擇主類別</option>
                            {data && data.data.map((item, index) => (
                                <option key={index} value={item.main_category}>{item.main_category}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col ml-2'>
                        <p className='text-lg font-semibold text-main-black'>次類別</p>
                        <select className='w-[150px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909] appearance-none arrowdown'
                            disabled={!product.mainCategory}
                            value={product.selectedSubCategory}
                            onChange={(e) => handleSubCategoryChange(product.id, e.target.value)}>
                            <option value=''>選擇次類別</option>
                            {Array.isArray(product.subCategories) && product.subCategories.map((subCategory) => (
                                <option key={subCategory} value={subCategory}>{subCategory}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='flex flex-col mb-6'>
                    <p className='text-lg font-semibold text-main-black'>商品名稱</p>
                    <input className='w-[311px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]
                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        placeholder='e.g. 美國熱銷薯片'
                        value={product.name}
                        onChange={(e) => handleProductChange(product.id, 'name', e.target.value)}></input>
                </div>
                <div className='flex flex-col mb-6'>
                    <p className='text-lg font-semibold text-main-black'>商品說明</p>
                    <textarea className='w-[311px] h-[120px] rounded-[18px] border-[1.5px] border-[#ebd9c9] px-3 py-2 mt-2 text-[#291909]
                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        placeholder='e.g. 薯片1組(共10包)'
                        value={product.description}
                        onChange={(e) => handleProductChange(product.id, 'description', e.target.value)}/>
                </div>
                <div className='flex flex-col mb-6'>
                    <div className='flex justify-satrt items-center mb-4'>
                        <p className='text-lg font-semibold text-main-black'>商品圖片</p>
                        <p className='text-sm text-[#8a8a97] ml-2'>圖片比例1:1，最多15張</p>
                    </div>
                    <div className='w-full overflow-x-auto'>
                        <div className='flex flex-nowrap min-w-max'>
                            {product.images.length < 15 && 
                                <div className='flex flex-col justify-center items-center w-[100px] h-[100px] bg-[#f7f7f9] rounded-lg hover:opacity-90
                                    border-2 border-[#dedede] border-dotted cursor-pointer'
                                    onClick={() => document.getElementById(`fileInput-${product.id}`).click()}>
                                    <img alt='upload' src={upload} className='h-10 w-10 mb-2'/>
                                    <p className='text-main-brown font-semibold'>新增圖片</p>
                                </div>
                            }
                            {product.images.map((imageObject, index) => (
                                <div key={index} className="min-w-[100px] w-[100px] h-[100px] object-cover object-center ml-4 relative">
                                        <img src={imageObject.preview} alt="Thumbnail" className="w-full h-full object-cover object-center rounded-md border-2 border-[#dedede] border-dotted" />
                                    <button onClick={() => deleteFile(product.id, imageObject.id)} className="ml-2 cross h-6 w-6 absolute top-1 right-1"></button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <input
                        type="file"
                        id={`fileInput-${product.id}`}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileSelect(product.id, e)}
                        multiple
                        accept="image/*"
                    />
                </div>
                <div className='flex mb-2'>
                        <div className='flex flex-col'>
                            <p className='text-lg font-semibold text-main-black'>原價</p>
                            <input className='w-[147px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                placeholder='e.g. 1000'
                                onChange={(e) => handleProductChange(product.id, 'price', e.target.value)}></input>
                        </div>
                        <div className='flex flex-col ml-4'>
                            <div className='flex justify-start items-center'>
                                <p className='text-lg font-semibold text-main-black'>特價</p>
                                <p className='text-sm text-[#8a8a97] ml-2'>非必填</p>
                            </div>
                            <input className='w-[147px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 mb-4 text-[#291909]
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                placeholder='e.g. 900'
                                onChange={(e) => handleProductChange(product.id, 'discounted_price', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='flex mb-2'>
                        <div className='flex flex-col'>
                            <p className='text-lg font-semibold text-main-black'>最少購買量</p>
                            <input className='w-[147px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                placeholder='e.g. 8'
                                onChange={(e) => handleProductChange(product.id, 'MOQ', e.target.value)}></input>
                        </div>
                        <div className='flex flex-col ml-4'>
                            <p className='text-lg font-semibold text-main-black'>總庫存</p>
                            <input className='w-[147px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 mb-4 text-[#291909]
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                placeholder='e.g. 100'
                                onChange={(e) => handleProductChange(product.id, 'stock_amount', e.target.value)}
                            />
                        </div>
                    </div>
                {products.length > 1 && (
                    <button className="cross h-6 w-6 absolute top-2 right-2"
                    onClick={() => deleteProduct(product.id)}></button>
                )}
                {inputError && <p className='text-[#ff5b60] text-xs'>{inputError}</p>}
            </motion.div>
            ))}
            <button onClick={addProduct} className='w-[300px] h-[47px] bg-white border-[3px] border-main-brown text-main-brown font-semibold rounded-full mt-4 mb-44 hover:text-deep-brown hover:border-deep-brown'>+ 新增其他商品</button>
            
            <div className="bg-white w-full h-[113px] flex justify-center items-center fixed bottom-0 shadow-up">
                <button className="w-[343px] h-[50px] text-xl font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown"
                    onClick={handleCreateProduct}>確認</button>
            </div>
        </div>
    )
}

export default CreateProductPage;