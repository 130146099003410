import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSearchCampaignsQuery, useCreateOrdersMutation, useGetRemainingTimeQuery } from "../store/slice/apiSlice";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import left from '../images/left.svg';
import time from '../images/time.svg';
import caution from '../images/caution.svg';

function PurchasePage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignCode = queryParams.get('code');

    const { campaignId } = useParams();
    const { data, error } = useSearchCampaignsQuery({ campaignId, campaignCode });
    const [descriptions, setDescriptions] = useState([]);

    useEffect(() => {
        console.log(data);
    }, [data]);

    useEffect(() => {
        if (data) {
            const formattedDescriptions = data.data.products.map(product => 
                product.description
                .replaceAll('\r\n', '<br>')
                .replaceAll('\n', '<br>')
                .replaceAll('\r', '<br>')
            );
            setDescriptions(formattedDescriptions);
        }
    }, [data]);
    

    const [activeIndex, setActiveIndex] = useState(0);
    const mainSwiperRef = useRef(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const handleThumbnailClick = (index) => {
        const firstImageIndex = data.data.products.slice(0, index).reduce((acc, product) => acc + product.product_images.length, 0);
        if (mainSwiperRef.current) {
            mainSwiperRef.current.slideTo(firstImageIndex);
        }
    };
    const getProductIndexByImageIndex = (imageIndex) => {
        let totalImages = 0;
        for (let i = 0; i < data.data.products.length; i++) {
            totalImages += data.data.products[i].product_images.length;
            if (imageIndex < totalImages) {
                return i;
            }
        }
        return 0;
    };    
    const handleSlideChange = (swiper) => {
        if (data) {
            const productIndex = getProductIndexByImageIndex(swiper.realIndex);
            setActiveIndex(productIndex);
        }
    };

    const { data: endTime } = useGetRemainingTimeQuery(data && data.data.end_datetime, {
        pollingInterval: 1000  // 確保在 hook 調用時設置了輪詢間隔
    });    

    const days = Math.floor((endTime / (1000 * 60 * 60 * 24)));
    const hours = Math.floor((endTime / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((endTime / (1000 * 60)) % 60);
    const seconds = Math.floor((endTime / 1000) % 60);

    const [quantities, setQuantities] = useState({});

    useEffect(() => {
        if (data && data.data.products.length > 0) {
            const initialQuantities = data.data.products.reduce((acc, product) => {
                acc[product.product_id] = 1;
                return acc;
            }, {});
            setQuantities(initialQuantities);
        }
    }, [data]);

    const handleIncrement = (productId) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 1) + 1
        }));
    };
    
    const handleDecrement = (productId) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: Math.max((prevQuantities[productId] || 1) - 1, 1)
        }));
    };

    const handleInputChange = (productId, value) => {
        const newValue = parseInt(value, 10);
        if (!isNaN(newValue) && newValue >= 0) {
            setQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: newValue
            }));
        } else {
            setQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: 0
            }))
        }
    };

    const [selected, setSelected] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    
    const handleCheckboxChange = (productId) => {
        setSelected(prev => ({
            ...prev,
            [productId]: !prev[productId]
        }));
    };
    
    const calculateTotal = () => {
        let total = 0;
        if (data) {
            data.data.products.forEach(product => {
                if (selected[product.product_id]) {
                    const price = product.discounted_price ? product.discounted_price : product.price;
                    const quantity = quantities[product.product_id] || 1;
                    total += price * quantity;
                }
            });
            setTotalPrice(total);
        }
    };
    
    useEffect(() => {
        calculateTotal();
    }, [selected, quantities]);

    const [selectedPickupId, setSelectedPickupId] = useState(null);
    const handleRadioChange = (e) => {
        setSelectedPickupId(Number(e.target.value));
    };

    const [showDiv, setShowDiv] = useState(false);
    const [alert, setAlert] = useState(false);
    const navigate = useNavigate();
    const [purchase] = useCreateOrdersMutation();
    const [purchaseError, setPurchaseError] = useState('');
    const handlePurchase = async () => {
        if (!showDiv) {
            setShowDiv(true);
            return;
        }

        if (!selectedPickupId) {
            setPurchaseError('請選擇一個取貨點');
            return;
        }

        const orderItems = Object.keys(selected)
        .filter(productId => selected[productId])
        .map(productId => {
            const product = data.data.products.find(p => p.product_id === parseInt(productId));
            const price = product ? (product.discounted_price || product.price) : 0;  // 如果找不到商品，價格預設為0
            return {
                product_id: parseInt(productId),
                quantity: quantities[productId] || 1,
                price: price
            };
        });

        const allItemsMeetMOQ = orderItems.every(item => {
            const product = data.data.products.find(p => p.product_id === item.product_id);
            return item.quantity >= (product?.MOQ || 1); // 如果MOQ未定義，則預設為1
        });

        if (!allItemsMeetMOQ) {
            setAlert(true);
            return;
        }

        if (orderItems.length === 0) {
            setAlert(true);
            return;
        }

        const orderData = {
            campaign_id: parseInt(campaignId),
            pickup_id: selectedPickupId,
            order_items: orderItems
        }

        try {
            const result = await purchase(orderData).unwrap();

            console.log(orderData);
            console.log(result);
            navigate('/purchasesuccess');

        } catch (error) {
            console.error('訂購失敗', error);
            if (error.status === 422) {
                setPurchaseError('請檢查是否確實輸入必填欄位');
            } else if (error.status === 401) {
                setPurchaseError('此功能需登入方可使用');
            } else if (error.status === 460) {
                setPurchaseError('請檢查下單數量不可大於庫存');
            } else {
                setPurchaseError('請檢查網路連線')
            }
        }
    };

    return (
        <div className="bg-[#f5f5f5] w-full h-auto min-h-screen flex flex-col items-center">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/purchasecode'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">商品頁</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            {error ? <p className="text-main-black mt-8">此功能需登入方可使用</p> :
            <div className="w-[375px] flex flex-col">
                <div>
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#ccc',
                            '--swiper-pagination-color': '#ccc',
                        }}
                        loop={true}
                        spaceBetween={0}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="h-[390px] w-full bg-white select-none"
                        onSlideChange={handleSlideChange}
                        onSwiper={(swiper) => mainSwiperRef.current = swiper}
                        >
                        {data && data.data.products.flatMap((product, index) => (
                        product.product_images && (
                        product.product_images.map((image, imgIndex) => (
                        <SwiperSlide key={`product-${index}-image-${imgIndex}`}>
                        <img alt='option' src={image.image_url} className="w-full h-full object-cover" />
                        </SwiperSlide>
                            ))
                            )
                        ))}
                    </Swiper>
                    <p className="bg-white font-semibold text-main-black py-2 pl-4">{data && data.data.products[activeIndex]?.name}</p>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={1}
                        slidesPerView={3.2}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="h-[120px] w-full pl-3 bg-white"
                        >
                        {data && data.data.products.flatMap((product, index) => (
                        product.product_images && (
                        product.product_images.map((image, imgIndex) => (
                        <SwiperSlide key={`product-${index}-image-${imgIndex}`}
                            onClick={() => handleThumbnailClick(index)}>
                        <img alt='option' src={image.image_url} className='w-[100px] h-[100px] rounded-md cursor-pointer object-cover' />
                        </SwiperSlide>
                            ))
                            )
                        ))}
                    </Swiper>
                    <div className="bg-white mb-2 shadow-md flex pb-2 pl-4">
                        <img alt='time' src={time} />
                        <p className="text-xs text-main-brown ml-1 font-mono">{`倒數${days || 0}天${hours || 0}時${minutes || 0}分${seconds || 0}秒`}</p>
                    </div>
                </div>
                {data && data.data.products.map((product, index) => (
                    <div className="w-full h-auto bg-white shadow-md pt-2 pb-4 px-4 mb-2"
                        key={index}>
                        <div className="flex pb-4 border-b-[1px]">
                            <img alt='product' src={product.product_images[0].image_url}
                                className="w-[110px] h-[110px] rounded-lg object-cover" />
                            <div className="flex flex-col ml-3">
                                <div className="flex h-full items-end">
                                    {product.discounted_price 
                                        ? <><p className="text-xs font-semibold text-main-black line-through mb-1">${product.price}</p>
                                        <p className="text-xl font-semibold text-[#e34a4f]">${product.discounted_price}</p>
                                        </>
                                        : <p className="text-xl font-semibold text-[#e34a4f]">${product.price}</p>
                                    }
                                </div>
                                <div className="flex mt-2">
                                    <p className="text-xs text-main-brown">最少購買{product.MOQ}件</p>
                                    <p className="text-[#d2d2d2] text-xs mx-2">|</p>
                                    <p className="text-xs text-main-brown">熱銷倒數{product.stock_amount}件</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex bg-white py-4 border-b-[1px]">
                            <input type='checkbox' checked={selected[product.product_id] || false}
                               onChange={() => handleCheckboxChange(product.product_id)} />
                            <p className="text-base font-medium text-main-black ml-2">{product.name}</p>
                        </div>
                        <div className="flex justify-between w-full py-4">
                            <p className="text-xl text-main-black font-semibold my-2">數量</p>
                            <div className='flex justify-center items-center'>
                                <button className={`text-gray-pr bg-[#f8f8f8] text-3xl w-10 h-10 flex justify-center items-center rounded-full pb-1 ${quantities[product.product_id]===1 ? 'text-[#adadad]' : ''}`}
                                    onClick={() => handleDecrement(product.product_id)}>-</button>
                                <input type="text" className='text-xl text-main-brown font-bold w-[90px] h-11 rounded-full border-2 border-[#ebd9c9] mx-4 text-center
                                focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                    value={quantities[product.product_id] || 0}
                                    onChange={e => handleInputChange(product.product_id, e.target.value)}
                                    min="1"
                                />
                                <button className='text-gray-pr bg-[#f8f8f8] text-3xl w-10 h-10 flex justify-center items-center rounded-full pb-1'
                                    onClick={() => handleIncrement(product.product_id)}>+</button>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="w-full h-auto bg-white shadow-md flex flex-col py-3 px-4 mb-32">
                    <p className="text-[19px] font-semibold text-main-black mb-4">詳細說明</p>
                    {data && data.data.products.map((product, index) => (
                        <div className="flex flex-col mb-3" key={index}>
                            <p className="text-main-black mb-1 font-semibold">{product.name}</p>
                            <div dangerouslySetInnerHTML={{ __html: descriptions[index] }} />
                        </div>
                    ))}
                </div>
            </div>
            }
            {showDiv &&
            <div className="fixed inset-0 bg-black/60 flex items-end pb-[113px] z-10" onClick={() => setShowDiv(false)}>
                <div className="flex justify-center bg-white w-full h-auto py-2 px-4 z-20 " onClick={(event) => event.stopPropagation()}>
                    <div className="flex flex-col w-[343px] pt-4 pb-5 relative">            
                        <p className="text-xl text-main-black font-semibold mb-2">取貨點</p>
                        {data && data.data.pickups.map((pickup) => (
                            <div className="flex my-2" key={pickup.id}>
                                <input type='radio' name='pickup'
                                    value={pickup.id} 
                                    onChange={handleRadioChange}
                                    checked={selectedPickupId === pickup.id}/>
                                <div className="flex justify-between w-full">
                                    <p className="text-main-black font-medium ml-2">{pickup.location}</p>
                                    <p className="text-main-black font-medium ml-2">{pickup.opening_hours}</p>
                                </div>
                            </div>
                        ))}
                        {purchaseError && <p className="text-[#ff5b60] text-xs absolute top-[130px] left-0">{purchaseError}</p>}
                    </div>
                </div>
            </div>
            }
            {alert && 
            <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-20" onClick={() => setAlert(false)}>
                <div className="flex justify-center bg-white w-[300px] h-auto p-6 rounded-2xl z-30 relative" onClick={(event) => event.stopPropagation()}>
                    <div className="flex flex-col w-full items-start">
                        <img alt='alert' src={caution} className="w-10 h-10 mb-6" />
                        <p className="text-lg text-main-black font-semibold mb-3">注意</p>
                        <p className="text-main-black">您的購買數量沒有達到</p>
                        <p className="text-main-brown">最少購買數量</p>
                        <button className="bg-main-brown text-white font-semibold w-[252px] h-[44px] mt-6 rounded-full"
                            onClick={() => setAlert(false)}>確認</button>                   
                    </div>
                </div>
            </div>
            }
            <div className="bg-white w-full h-[113px] flex justify-center items-center fixed bottom-0 shadow-up z-10">
                <button className="w-[343px] h-[50px] text-xl font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown"
                    onClick={handlePurchase}>${totalPrice}，下一步</button>
            </div>
        </div>
    )
}

export default PurchasePage;