import success from '../images/success_large.svg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function PurchaseSuccessPage() {
    return (
        <div className='flex flex-col items-center min-h-screen'>
            <motion.img alt='success' src={success} className='mt-[100px]'
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20
            }} />
            <p className='text-main-black font-semibold text-2xl mt-10'>購買成功</p>
            <div className='flex mt-4'>
                <p className='text-[#8a8a97]'>即刻起您可以在</p>
                <p className='text-main-brown font-semibold'>訂單管理</p>
                <p className='text-[#8a8a97]'>頁面</p>
            </div>
            <p className='text-[#8a8a97]'>查看您購買的商品</p>
            <div className="bg-white w-full h-[160px] flex flex-col justify-center items-center fixed bottom-0 shadow-up">
                <Link to='/buyer'>
                    <button className="w-[343px] h-[50px] font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown">返回 跟團仔頁面</button>
                </Link>
                <Link to='/purchaselist'>
                <button className="w-[343px] h-[50px] font-semibold border-2 border-main-brown text-main-brown rounded-full mt-2 hover:text-deep-brown hover:border-deep-brown">前往 購買清單</button>
                </Link>
            </div>
        </div>
    )
}

export default PurchaseSuccessPage;