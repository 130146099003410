import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setRegisterPhone, setRegisterPassword, setRegisterName, setAuthToken, register, setLoginRole } from '../store/slice/userSlice';
import { useSendSmsMutation, useRegisterUserMutation } from '../store/slice/apiSlice';
import line from '../images/line.svg';
import success from '../images/success.svg';

function Register() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const phone = useSelector(state => state.user.register.phone);
    const password = useSelector(state => state.user.register.password);
    const name = useSelector(state => state.user.register.name);
    
    const [phoneError, setPhoneError] = useState(false);
    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        dispatch(setRegisterPhone(newPhone));
        if (!/^09\d{8}$/.test(newPhone)) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    const [passwordError, setPasswordError] = useState(false);
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        dispatch(setRegisterPassword(newPassword));
        if (/^[A-Za-z0-9]{8,16}$/.test(newPassword)) {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }
    }

    const [nameError, setNameError] = useState(false);

    const validateName = (name) => {
        const re = /^[a-zA-Z\u4e00-\u9fa5]*$/;
        return re.test(name);
    }

    const handlenameChange = (e) => {
        const newName = e.target.value;
        dispatch(setRegisterName(newName));
        if (validateName(newName)) {
            setNameError(false);
        } else {
            setNameError(true);
        }
    }

    const [showDiv, setShowDiv] = useState(false);
    const [registerError, setRegisterError] = useState('');
    const [registerUser] = useRegisterUserMutation();
    const [sendSms] = useSendSmsMutation();

    const handleRegister = async () => {
        try {
            const result = await registerUser({
                name,
                password,
                phone,
                sms_code: verifycode
            }).unwrap();
    
            console.log('註冊成功', result);
    
            dispatch(setAuthToken(result.access_token));
            dispatch(setLoginRole(result.is_seller));
            dispatch(register());
    
            setShowDiv(true);
    
            setTimeout(() => {
                setShowDiv(false);
                navigate('/home');
            }, 1500); 
        } catch (error) {
            console.error('註冊失敗', error);
            if (error.status === 400 && error.data.detail.includes('Wrong SMS code')) {
                setRegisterError('驗證碼錯誤，請重新輸入');
            } else if (error.status === 400 && error.data.detail.includes('Duplicate entry')) {
                setRegisterError('此用戶已存在，請直接登入');
            } else {
                setRegisterError('請檢查網路連線')
            }
        }
    };
    

    const [verifycode, setVerifycode] = useState('')
    const handleVerifycodeChange = (e) => {
        setVerifycode(e.target.value);
    }

    const handleSendSms = async () => {
        setIsButtonDisabled(true);
        setCountdown(60);

        try {
          const result = await sendSms({ phone, is_forget_password: false }).unwrap();
          console.log('SMS sent successfully', result);
        } catch (error) {
            if (error.status === 400 && error.data.detail.includes('User already verified')) {
                setRegisterError('此手機號碼已註冊，請直接登入')
            } else {
                setRegisterError('請檢查網路連線')
            }
          console.error('Failed to send SMS:', error);
        }
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        let interval;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown > 0) {
                        return prevCountdown - 1;
                    }
                    clearInterval(interval);
                    setIsButtonDisabled(false);
                    return 0;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    return (
        <div key='registerForm'>
            {showDiv && 
                <div className='z-10 fixed top-[166px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                    <img alt='success' src={success} className='py-4' />
                    <p className='text-[#45a5de] font-semibold text-2xl pl-2'>註冊完成</p>
                </div>
            }
            <div className='flex flex-col items-center py-8 px-4'>
                <div className="w-[343px] relative">
                    <input placeholder='請輸入使用者名稱  e.g. Emma'
                        value={name}
                        onChange={handlenameChange}
                        className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 mb-6 signup-id
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                        ${nameError ? 'border-2 border-[#ff5b60]' : ''}`}></input>
                        {nameError ? <div className='text-[#ff5b60] text-xs absolute top-[54px] left-[10px]'>只能包含英文和中文，請移除任何標點符號或特殊字符</div> : ''}
                </div>
                <div className="w-[343px] relative">
                    <input placeholder='請輸入手機號碼'
                        className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 mb-6 login-phone
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                        ${phoneError ? 'border-2 border-[#ff5b60]' : ''}`}
                        value={phone}
                        onChange={handlePhoneChange}></input>
                        {phoneError ? <div className='text-[#ff5b60] text-xs absolute top-[54px] left-[10px]'>手機號碼應為10個數字</div> : ''}
                </div>
                <div className='relative w-[343px] mb-6'>
                    <input placeholder='請輸入包含英文和數字的密碼'
                        className={`w-[343px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-12 login-password
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]
                        ${passwordError ? 'border-2 border-[#ff5b60]' : ''}`}
                        value={password}
                        type={passwordShown ? 'text' : 'password'}
                        onChange={handlePasswordChange}></input>
                    {passwordShown ? <div className="eye-slash-icon eye-icon-all text-main-brown" onClick={togglePasswordVisibility}></div> : <div className="eye-icon eye-icon-all text-main-brown" onClick={togglePasswordVisibility}></div>}
                    {passwordError ? <div className='text-[#ff5b60] text-xs absolute top-[55px] left-[10px]'>密碼應包含英文數字共8-16個字元</div> : ''}
                </div>
                <div className='flex w-[343px] relative'>
                    <input placeholder='請輸入驗證碼'
                        className='w-[226px] h-[52px] rounded-full border-2 border-[#ebd9c9] pl-6 mb-6
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        value={verifycode}
                        onChange={handleVerifycodeChange}></input>
                    {registerError && <p className="text-[#ff5b60] text-xs absolute top-[56px] left-[10px]">{registerError}</p>}
                    <button className={`text-white text-sm font-semibold py-4 h-[52px] ml-2 w-[109px] rounded-full hover:bg-deep-brown
                        ${isButtonDisabled ? 'bg-[#cdcdcd]' : 'bg-main-brown'}`}
                        disabled={isButtonDisabled}
                        onClick={handleSendSms}>取得驗證碼</button>
                        {isButtonDisabled && <p className="absolute top-[56px] right-6 text-xs text-[#cdcdcd]">{`請等待 ${countdown} 秒`}</p>}
                </div>
            </div>
            <div className='flex flex-col items-center mb-8 px-4'>
                <Link to='/forgetpassword' className='text-main-brown underline underline-offset-4 font-semibold hover:text-deep-brown'>忘記密碼？</Link>
                <button className='bg-main-brown text-white font-semibold py-4 w-[343px] my-3 rounded-full hover:bg-deep-brown'
                    onClick={handleRegister}>註冊</button>
                <button className='bg-[#00c300] text-white font-semibold py-3 w-[343px] rounded-full flex justify-center items-center hover:opacity-90'>
                    <img alt='line' src={line} className='flex w-8 h-8'/><p className='flex'>使用Line繼續</p>
                </button>
            </div>
        </div>
    )
}

export default Register;