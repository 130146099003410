import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetCampaignsQuery, useSellerOrdersQuery, useSellerOrdersDetailQuery, useDeleteOrdersMutation, useSellerOrdersUpdateMutation } from '../store/slice/apiSlice';
import TruncatedText from '../components/TruncateText'; 
import { motion } from 'framer-motion';
import left from '../images/left.svg';
import check from '../images/check.svg';
import xmark from '../images/xmark.svg';
import caution from '../images/caution.svg';
import success from '../images/success.svg';
import Switch from '../components/Switch';
import emptybox from '../images/emptybox.svg';

function ManageOrderPage() {
    const [status, setStatus] = useState('開團中');
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [campaignName, setCampaignName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const { data: campaignsData } = useGetCampaignsQuery({
        campaign_status: status,
        page: 1,
        page_size: 100
    });

    const { data, error } = useSellerOrdersQuery({
        order_status: status,
        campaign_id: 0,
        campaign_name: campaignName,
        buyer_phone: searchTerm,
        page: currentPage,
        page_size: 6
    });
    
    useEffect(() => {
        if (data && data.total_pages) {
            setMaxPage(data.total_pages || 1);
        } else {
            setMaxPage(1);
        }
        console.log(data);
        console.log(error)
    }, [data, error]);

    const handleCampaignChange = (event) => {
        const selectedCampaignName = event.target.value;
        setCampaignName(selectedCampaignName);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getPageNumbers = () => {
        let pages = [];
        let startPage, endPage;
    
        if (maxPage <= 4) {
            // 少於6頁時，直接顯示所有頁碼
            startPage = 1;
            endPage = maxPage;
        } else {
            // 超過6頁時，計算起始和結束頁碼
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 4;
            } else if (currentPage + 2 >= maxPage) {
                startPage = maxPage - 3;
                endPage = maxPage;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }
    
        // 生成頁碼範圍
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    useEffect(() => {
        setCurrentPage(1);
        // setCampaignName('');
    }, [status]);

    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const orderDetail = useSellerOrdersDetailQuery(selectedOrderId, { skip: !selectedOrderId });

    const handleOrderClick = (orderId) => {
        setSelectedOrderId(orderId);
    };

    useEffect(() => {
        console.log(selectedOrderId);
        console.log(orderDetail);
    }, [selectedOrderId, orderDetail]);

    const [showDelete, setShowDelete] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    const [deleteOrder] = useDeleteOrdersMutation();
    const handleDeleteOrder = async () => {
        try {
            const result = await deleteOrder(selectedOrderId).unwrap();
            console.log(result);

            setShowDelete(false);
            setSelectedOrderId(null);
            setShowDeleteSuccess(true);

            setTimeout(() => {
                setShowDeleteSuccess(false);
            }, 1500);
        } catch (error) {
            console.error('Failed to delete order', error);
        }
    }

    const [isPaid, setIsPaid] = useState(false);
    const [isPickedUp, setIsPickedUp] = useState(false);

    useEffect(() => {
        if (orderDetail && orderDetail.data && orderDetail.data.data) {
            setIsPaid(orderDetail.data.data.is_paid);
            setIsPickedUp(orderDetail.data.data.is_picked_up);
        }
    }, [orderDetail]);

    const handlePaidToggle = () => {
        setIsPaid(current => !current);
    };

    const handlePickedUpToggle = () => {
        setIsPickedUp(current => !current);
    };

    const [productDetails, setProductDetails] = useState({});
    const [totals, setTotals] = useState({ totalItems: 0, totalAmount: 0 });

    useEffect(() => {
        if (orderDetail && orderDetail.data && orderDetail.data.data && orderDetail.data.data.order_details) {
            const initialDetails = {};
            let initialTotalItems = 0;
            let initialTotalAmount = 0;
            orderDetail.data.data.order_details.forEach(product => {
                initialDetails[product.product_id] = {
                    quantity: product.quantity,
                    itemSubtotal: product.item_subtotal,
                    totalItems: product.total_items,
                    totalAmount: product.total_amount
                };
                initialTotalItems += product.quantity;
                initialTotalAmount += product.item_subtotal;
            });
            setProductDetails(initialDetails);
            setTotals({ totalItems: initialTotalItems, totalAmount: initialTotalAmount });
        }
    }, [orderDetail]);

    const handleQuantityChange = (productId, newQuantity) => {
        const unitPrice = orderDetail.data.data.order_details.find(product => product.product_id === productId).unit_price;
        const newItemSubtotal = newQuantity * unitPrice;

        setProductDetails(current => {
            const newDetails = { ...current };
            newDetails[productId].quantity = newQuantity;
            newDetails[productId].itemSubtotal = newItemSubtotal;

            const newTotalItems = Object.values(newDetails).reduce((sum, detail) => sum + detail.quantity, 0);
            const newTotalAmount = Object.values(newDetails).reduce((sum, detail) => sum + detail.itemSubtotal, 0);

            setTotals({ totalItems: newTotalItems, totalAmount: newTotalAmount });
            return newDetails;
        });
    };

    const [updateOrder] = useSellerOrdersUpdateMutation();
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const prepareUpdatedOrderDetails = () => {
        return orderDetail.data.data.order_details.map(product => ({
          product_id: product.product_id,
          quantity: productDetails[product.product_id]?.quantity || 0,
        }));
    };

    const handleUpdateOrder = async () => {

        const updatedOrder = {
            order_id: orderDetail.data.data.id,
            is_paid: isPaid,
            is_picked_up: isPickedUp,
            updated_order_details: prepareUpdatedOrderDetails(),
        };

        try {
            const response = await updateOrder(updatedOrder).unwrap();
            console.log(response);
            
            setSelectedOrderId(null)
            setUpdateSuccess(true);

            setTimeout(() => {
                setUpdateSuccess(false);
            }, 1500);
            } catch (error) {
              console.error('Failed to update order', error);
            }
    }

    return (
        <div className="bg-[#f5f5f5] w-full max-h-max min-h-screen flex flex-col items-center">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/seller'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">後台管理</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='flex w-full border-b-2 border-[#eeeeee] bg-white'>
                <Link to='/managecampaign' className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-[#858585]'>團購管理</div>
                </Link>
                <div className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-main-brown'>訂單管理</div>
                    <div className='w-[40%] h-[5px] top-radious bg-main-brown flex'></div>
                </div>
                <Link to='/managewholesale' className='w-1/3 flex flex-col items-center'>
                    <div className='text-lg font-semibold cursor-pointer mb-1 text-[#858585]'>批貨管理</div>
                </Link>
            </div>
            <div className='flex flex-col w-full h-full items-center justify-between'>
                <div className='flex flex-col'>
                    <div className='flex my-4'>
                        <select className='text-main-black w-[163.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] appearance-none arrowdown
                                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                    value={campaignName}
                                    onChange={handleCampaignChange}>
                            <option value=''>全部的團購</option>
                            {campaignsData && campaignsData.data.map((campaign, index) => (
                                <option key={index} value={campaign.campaign_name}>{campaign.name}</option>
                            ))}
                        </select>
                            <input placeholder='末四碼搜尋' className='w-[163.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black ml-4 search
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <div className='flex w-[343px] h-[44px] mb-4'>
                        <button className={`w-1/4 rounded-l-full border-[1px] border-[#ebd9c9] font-semibold flex items-center justify-center pr-1
                            ${status === '開團中' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('開團中')}>
                            {status === '開團中' && <img alt='check' src={check} className='w-5 h-5 mr-[1px] ml-2' />}開團中</button>
                        <button className={`w-1/4 border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                            ${status === '已結單' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已結單')}>
                            {status === '已結單' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已結單</button>
                        <button className={`w-1/4 border-[1px] border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center
                            ${status === '已到貨' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已到貨')}>
                            {status === '已到貨' && <img alt='check' src={check} className='w-5 h-5 mr-1' />}已到貨</button>
                        <button className={`w-1/4 border-[1px] rounded-r-full border-[#ebd9c9] text-[#858585] font-semibold flex items-center justify-center pr-2
                            ${status === '已完成' ? 'bg-[#f4ecdd] text-main-brown' : 'bg-white text-[#858585]'}`} onClick={() => setStatus('已完成')}>
                            {status === '已完成' && <img alt='check' src={check} className='w-5 h-5 mr-1 ml-[1.5px]' />}已完成</button>
                    </div>
                    <div className='flex flex-col min-h-[456px]'>
                    {data && data.data.length > 0 ? data.data
                    .filter(order => {
                        if (campaignName !== '') {
                            return order.campaign_name === campaignName;
                        } else {
                            return true;
                        }
                    })
                    .map((item, index) => (
                        <motion.div key={index} className='w-[343px] h-[60px] bg-white rounded-lg flex items-center p-2 mb-4 justify-between shadow-md cursor-pointer'
                            onClick={() => handleOrderClick(item.order_id)}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.4 }} >
                            <p className='text-main-black w-[100px] ml-2'>
                                <TruncatedText text={item.buyer_name} maxLength={9} />
                            </p>
                            <p className='text-main-black w-[67px]'>{item.order_id}</p>
                            <p className='text-[#858585] w-[160px] mr-2'>
                                <TruncatedText text={item.campaign_name} maxLength={18} />
                            </p>
                        </motion.div>
                    )) : (<div className='flex flex-col items-center min-h-[500px] justify-center'>
                        <img alt='empty' src={emptybox} />
                        <p className='text-[#858585] mt-4'>還沒有進行中的團購？</p>
                        <p className='text-[#858585]'>讓我們一起創造驚人的銷售奇蹟！</p>
                    </div>)}
                    </div>
                    {selectedOrderId && orderDetail.data && (
                        <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-10" onClick={() => setSelectedOrderId(null)}>
                            <div className="bg-white p-4 rounded-lg w-[343px] h-auto relative" onClick={(event) => event.stopPropagation()}>
                                <img alt='close' src={xmark} className='absolute top-4 right-4 h-6 w-6 cursor-pointer' 
                                    onClick={() => setSelectedOrderId(null)}/>
                                <p className='text-main-black text-lg font-semibold mb-4'>訂單詳情</p>
                                <img alt='product' src={orderDetail.data.data.order_details[0].product_image} className='w-[110px] h-[110px] rounded-lg mb-4' />
                                <div className='flex justify-between'>
                                    <p className='text-main-black text-base font-semibold mb-4'>{orderDetail.data.data.campaign_name}</p>
                                    <p className='text-main-black text-base font-semibold mb-4'>{orderDetail.data.data.id}</p>
                                </div>
                                {orderDetail.data.data.order_details.map((product, index) => (
                                    <div key={index} className='flex justify-between mb-4'>
                                        <p className='text-main-black text-base w-[150px]'>{product.product_name}</p>
                                        <input className='text-main-black text-base w-12 text-center border-2 border-[#ebd9c9] rounded-full
                                            focus:outline-none focus:border-[#f2ca7f]'
                                            value={productDetails[product.product_id]?.quantity || ''}
                                            onChange={(e) => handleQuantityChange(product.product_id, parseInt(e.target.value, 10) || 0)}
                                            ></input>
                                        <p className='text-main-black text-base w-[100px] text-end'>
                                        ${Math.round(productDetails[product.product_id]?.itemSubtotal) || '0'}
                                        </p>
                                    </div>
                                ))}
                                <div className='flex justify-between border-y-[1px] pt-2 pb-6'>
                                    <p className='text-main-black text-base w-[150px]'>總額</p>
                                    <p className='text-main-black text-base w-12 text-end'>{totals.totalItems}</p>
                                    <p className='text-[#e34a4f] font-semibold text-base w-[100px] text-end'>${Math.round(totals.totalAmount)}</p>
                                </div>
                                <div className='flex justify-between mt-6'>
                                    <p className='text-main-black text-base font-medium mb-3'>購買者</p>
                                    <p className='text-main-black'>{orderDetail.data.data.buyer_name}</p>
                                </div>
                                <div className='flex justify-between'>
                                    <p className='text-main-black text-base font-medium mb-3'>電話 末四碼</p>
                                    <p className='text-main-black'>{orderDetail.data.data.phone}</p>
                                </div>
                                <div className='flex justify-between'>
                                    <p className='text-main-black text-base font-medium mb-3'>取貨點</p>
                                    <p className='text-main-black'>{orderDetail.data.data.pickup}</p>
                                </div>
                                <div className='flex justify-end'>
                                    <p className='text-main-brown mb-3'>#常客 #熱愛糕點</p>
                                </div>
                                <div className='flex justify-end items-center mb-3'>
                                    <p className='text-main-black mr-2'>未付款</p>
                                    <Switch isToggled={isPaid} toggleSwitch={handlePaidToggle} />
                                    <p className='text-main-black ml-2'>已付款</p>
                                </div>
                                <div className='flex justify-end items-center mb-6'>
                                    <p className='text-main-black mr-2'>未取貨</p>
                                    <Switch isToggled={isPickedUp} toggleSwitch={handlePickedUpToggle} />
                                    <p className='text-main-black ml-2'>已取貨</p>
                                </div>
                                <div className='flex justify-center'>
                                    <button className='text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[55px] rounded-full hover:text-deep-brown hover:border-deep-brown'
                                        onClick={() => setShowDelete(true)}>取消訂單</button>
                                    <button className='text-white font-semibold bg-main-brown w-[190px] h-[55px] rounded-full ml-4 hover:bg-deep-brown'
                                            onClick={handleUpdateOrder}>確認</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {showDelete && 
                        <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-10" onClick={() => setShowDelete(false)}>
                            <div className="bg-white p-6 rounded-lg w-[300px] h-auto relative" onClick={(event) => event.stopPropagation()}>
                                <img alt='close' src={xmark} className='absolute top-4 right-4 h-6 w-6 cursor-pointer' 
                                    onClick={() => setShowDelete(false)}/>
                                <img alt='caution' src={caution} className='h-10 w-10 mb-6' />
                                <p className='text-main-black text-lg font-semibold mb-3'>注意</p>
                                <p className='text-main-black text-base mb-8'>
                                    點擊取消訂單，此訂單將更改為<span className='text-main-brown'>不成立</span>狀態，您確定要執行此操作嗎？
                                </p>
                                <div className='flex justify-center'>
                                    <button className='text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[48px] rounded-full'
                                        onClick={() => setShowDelete(false)}>取消</button>
                                    <button className='text-white font-semibold bg-main-brown w-[190px] h-[48px] rounded-full ml-2'
                                            onClick={handleDeleteOrder}>確定取消訂單</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {showDeleteSuccess && 
                    <div className='z-10 fixed top-[103px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                        <img alt='success' src={success} className='py-4' />
                        <p className='text-[#45a5de] font-semibold text-2xl pl-2'>取消訂單</p>
                    </div>
                }
                {updateSuccess && 
                    <div className='z-10 fixed top-[103px] w-full bg-[#e6f4ff] flex justify-center items-center'>
                        <img alt='success' src={success} className='py-4' />
                        <p className='text-[#45a5de] font-semibold text-2xl pl-2'>修改完成</p>
                    </div>
                }
                <div className='flex justify-end mt-10 mb-20 pb-20'>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer'>&lt;</button>
                    {currentPage > 2 && maxPage > 5 && (
                    <>
                        <button onClick={() => handlePageChange(1)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>1</button>
                        {currentPage > 3 && <span className='mr-3'>...</span>}
                    </>
                )}
                {getPageNumbers().map((page) => (
                    <button key={page} onClick={() => handlePageChange(page)} className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}>
                        {page}
                    </button>
                ))}
                {currentPage < (maxPage - 2) && maxPage >= 6 && (
                    <>
                        {currentPage < (maxPage - 2) && <span className='mr-3'>...</span>}
                        <button onClick={() => handlePageChange(maxPage)} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3'>{maxPage}</button>
                    </>
                )}
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === maxPage} className='font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer'>&gt;</button>
                </div>
            </div>
        </div>
    )
}

export default ManageOrderPage;