import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import SellerPage from "./pages/SellerPage";
import StorePage from "./pages/StorePage";
import CreateProductPage from "./pages/CreateProductPage";
import StoreSuccessPage from "./pages/StoreSuccessPage";
import WholesalePage from "./pages/WholesalePage";
import ProductPage from "./pages/ProductPage";
import PurchaseSuccessPage from "./pages/PurchaseSuccessPage";
import WholesaleSuccessPage from "./pages/WholesaleSuccessPage";
import FastDeliveryPage from "./pages/FastDeliveryPage";
import ManageCampaignPage from "./pages/ManageCampaignPage";
import ManageOrderPage from "./pages/ManageOrderPage";
import ManageWholesalePage from "./pages/ManageWholesalePage";
import GroupBuyingPage from "./pages/GroupBuyingPage";
import BuyerPage from "./pages/BuyerPage";
import PurchaseCodePage from "./pages/PurchaseCodePage";
import PurchasePage from "./pages/PurchasePage";
import DeliveryCodePage from "./pages/DeliveryCodePage";
import PurchaseListPage from "./pages/PurchaseListPage";
import EditStorePage from "./pages/EditStorePage";
import EditProductPage from "./pages/EditProductPage";
import DuplicateStorePage from "./pages/DuplicateStorePage";
import DuplicateProductPage from "./pages/DuplicateProductPage";
import WholesaleStorePage from "./pages/WholesaleStorePage";
import WholesaleProductPage from "./pages/WholesaleProductPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import NotificationPage from "./pages/NotificationPage";
import IntroductionPage from "./pages/IntroductionPage";

function App() {
    return (
        <ParallaxProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<IntroductionPage />} />
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/home' element={<HomePage />} />
                    <Route path='/seller' element={<SellerPage />} />
                    <Route path='/store' element={<StorePage />} />
                    <Route path='/createproduct' element={<CreateProductPage />} />
                    <Route path='/storesuccess' element={<StoreSuccessPage />} />
                    <Route path='/wholesale' element={<WholesalePage />} />
                    <Route path='/product/:productId' element={<ProductPage />} />
                    <Route path='/purchasesuccess' element={<PurchaseSuccessPage />} />
                    <Route path='/wholesalesuccess' element={<WholesaleSuccessPage />} />
                    <Route path='/fastdelivery' element={<FastDeliveryPage />} />
                    <Route path='/managecampaign' element={<ManageCampaignPage />} />
                    <Route path='/manageorder' element={<ManageOrderPage />} />
                    <Route path='/managewholesale' element={<ManageWholesalePage />} />
                    <Route path='/groupbuying' element={<GroupBuyingPage />} />
                    <Route path='/buyer' element={<BuyerPage />} />
                    <Route path='/purchasecode' element={<PurchaseCodePage />} />
                    <Route path='/purchase/:campaignId' element={<PurchasePage />} />
                    <Route path='/purchase' element={<PurchasePage />} />
                    <Route path='/deliverycode' element={<DeliveryCodePage />} />
                    <Route path='/purchaselist' element={<PurchaseListPage />} />
                    <Route path='/editstore/:campaignId' element={<EditStorePage />} />
                    <Route path='/editproduct/:campaignId' element={<EditProductPage />} />
                    <Route path='/duplicatestore/:campaignId' element={<DuplicateStorePage />} />
                    <Route path='/duplicateproduct/:newCampaignId' element={<DuplicateProductPage />} />
                    <Route path='/wholesalestore/:productId' element={<WholesaleStorePage />} />
                    <Route path='/wholesaleproduct/:productId' element={<WholesaleProductPage />} />
                    <Route path='/forgetpassword' element={<ForgetPasswordPage />} />
                    <Route path='/notification' element={<NotificationPage />} />
                </Routes>
            </BrowserRouter>
        </ParallaxProvider>
    )
}

export default App;