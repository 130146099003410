import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetCampaignsDetailQuery, useCreateCampaignsMutation } from '../store/slice/apiSlice';
import { v4 as uuidv4 } from 'uuid';
import left from '../images/left.svg';
import success from '../images/success_large.svg';
import caution from '../images/caution.svg';

function DuplicateStorePage () {
    const { campaignId } = useParams();
    const { data: campaignData } = useGetCampaignsDetailQuery(campaignId);

    const [inputs, setInputs] = useState([
        { id: uuidv4(), pickupPoint: '', openingHours: '' },
    ]);

    const handleAddClick = () => {
        const newInput = {
            id: uuidv4(),
            pickupPoint: '',
            openingHours: '',
        };
        setInputs([...inputs, newInput]);
    };

    const handleRemoveClick = (id) => {
        const newInputs = inputs.filter(input => input.id !== id);
        setInputs(newInputs);
    };

    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [note, setNote] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (campaignData) {
            setName(campaignData.data.name);
            setNote(campaignData.data.note);
            setInputs(campaignData.data.pickups.map(pickup => ({
                id: uuidv4(), // 或使用 pickup.id 如果後端提供唯一 ID
                pickupPoint: pickup.location,
                openingHours: pickup.opening_hours
            })));
            console.log(campaignData);
        }
    }, [campaignData]);

    useEffect(() => {
        let filledInputs = 0;

        if (startDate.trim()) filledInputs += 1;
        if (endDate.trim()) filledInputs += 1;
        if (name.trim()) filledInputs += 1;

        inputs.forEach(input => {
          if (input.pickupPoint.trim()) filledInputs += 1;
          if (input.openingHours.trim()) filledInputs += 1;
        });
        const totalInputs = inputs.length * 2 + 3;
        const newProgress = (filledInputs / totalInputs) * 100;
        setProgress(newProgress);
    }, [inputs, startDate, endDate, name]);
    
    const [showDiv, setShowDiv] = useState(false);
    const [storeError, setStoreError] = useState('')
    const [newCampaignId, setNewCampaignId] = useState('');
    const [createStore] = useCreateCampaignsMutation();
    const handleCreateStore = async () => {
        setStoreError('');

        const formattedInputs = inputs.map(({ id, pickupPoint, openingHours }) => ({
            id,
            location: pickupPoint,
            opening_hours: openingHours
        }));
        try {
            const result = await createStore({
                start_datetime: startDate,
                end_datetime: endDate,
                name,
                note,
                pickups: formattedInputs,
            }).unwrap();

            console.log('開團成功', result);
            localStorage.setItem('campaign_id', campaignId);
            setNewCampaignId(result.data.id);
            setStoreError('開團成功');
            setShowDiv(true);
        } catch (error) {
            if (error.status === 401) {
                setStoreError('此功能需賣家權限');
                setShowDiv(true);
            } else if (error.status === 400) {
                setStoreError('請確認欄位已填寫 or 檢查網路連線');
                setShowDiv(true);
            }
            console.error('開團失敗', error);
        }
    }

    return (
        <div className="bg-[#f5f5f5] w-full min-h-screen flex flex-col items-center">
            {showDiv && 
                <div className='bg-black/60 fixed inset-0 flex justify-center items-center z-10'
                    onClick={() => setShowDiv(false)}>
                    <div className='bg-white w-[320px] h-auto rounded-md p-4 flex flex-col items-center'
                        onClick={(e) => e.stopPropagation()}>
                        {storeError === '開團成功'
                            ? <img alt='success' src={success} className='mb-2 h-12 w-12' />
                            : <img alt='success' src={caution} className='mb-2' />
                        }
                        <p className='text-main-black mb-3 text-base'>{storeError}</p>
                        {storeError === '開團成功'
                            ? <Link to={`/duplicateproduct/${newCampaignId}`}><button className='bg-main-brown font-semibold text-white text-sm px-5 py-2 rounded-full'>下一步，建立商品</button></Link>
                            : <button className='bg-main-brown font-semibold text-white w-[150px] py-2 rounded-full text-sm'
                            onClick={() => setShowDiv(false)}>確認</button>
                        }
                    </div>
                </div>}
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/managecampaign'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">建立開團資訊</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <div className='w-full h-2 bg-[#e3e2e2]' >
                <div style={{ width: `${progress}%` }} className='h-full bg-[#f2ca7f] transition-width duration-500'></div>
            </div>
            <div className='w-[343px] h-auto bg-white rounded-lg p-4 shadow-lg m-4 mb-32'>
                <div className='flex flex-col mb-6'>
                    <p className='text-lg font-semibold text-main-black'>團購名稱</p>
                    <input className='w-[311px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]
                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                    placeholder='e.g. 3C產品精選'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className='flex mb-6'>
                    <div className='flex flex-col'>
                        <p className='text-lg font-semibold text-main-black'>起始日</p>
                        <input type='date' className='w-[150px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}></input>
                    </div>
                    <div className='flex flex-col ml-2'>
                        <p className='text-lg font-semibold text-main-black'>截止日</p>
                        <input type='date' className='w-[150px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}></input>
                    </div>
                </div>
                <div className='flex flex-col mb-6'>
                    {inputs.map((input) => (
                        <div key={input.id} className='flex mb-6 relative'>
                            <div className='flex flex-col'>
                                <p className='text-lg font-semibold text-main-black'>取貨點</p>
                                <input className='w-[150px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]
                                focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                    placeholder='e.g.國寶社區'
                                    value={input.pickupPoint}
                                    onChange={(e) => {
                                        const index = inputs.findIndex(i => i.id === input.id);
                                        const newInputs = [...inputs];
                                        newInputs[index].pickupPoint = e.target.value;
                                        setInputs(newInputs);
                                    }}
                                />
                            </div>
                            <div className='flex flex-col ml-2'>
                                <p className='text-lg font-semibold text-main-black'>開放時段</p>
                                <input className='w-[150px] h-[40px] rounded-full border-[1.5px] border-[#ebd9c9] px-3 mt-2 text-[#291909]
                                focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                                    placeholder='20:00-22:00 下午'
                                    value={input.openingHours}
                                    onChange={(e) => {
                                        const index = inputs.findIndex(i => i.id === input.id);
                                        const newInputs = [...inputs];
                                        newInputs[index].openingHours = e.target.value;
                                        setInputs(newInputs);
                                    }}
                                />
                            </div>
                            {inputs.length > 1 && (
                                <button className="cross h-6 w-6 absolute top-1 right-0"
                                onClick={() => handleRemoveClick(input.id)}></button>
                            )}
                        </div>
                    ))}
                    <p className='text-[#969297] text-lg font-semibold cursor-pointer ml-1'
                        onClick={handleAddClick}>+ 增加取貨點</p>
                </div>
                <div className='flex flex-col'>
                    <p className='text-lg font-semibold text-main-black'>備註</p>
                    <textarea className='w-[311px] h-[120px] rounded-[18px] border-[1.5px] border-[#ebd9c9] px-3 py-2 mt-2 text-[#291909]
                    focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]'
                        placeholder='請輸入備註內容'
                        value={note}
                        onChange={(e) => setNote(e.target.value)}/>
                </div>
            </div>
            <div className="bg-white w-full h-[113px] flex justify-center items-center fixed bottom-0 shadow-up">
                <button className="w-[343px] h-[50px] text-xl font-semibold text-white bg-main-brown rounded-full"
                    onClick={handleCreateStore}>確認開團</button>
            </div>
        </div>
    )
}

export default DuplicateStorePage;