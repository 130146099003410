import success from '../images/success_large.svg';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

function WholesaleSuccessPage() {
    const supplier_order_id = localStorage.getItem('supplier_order_id');
    const location = useLocation();
    const { productId } = location.state || {};
    return (
        <div className='flex flex-col items-center min-h-screen pb-44'>
            <motion.img alt='success' src={success} className='mt-[100px]'
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20
            }} />
            <p className='text-main-black font-semibold text-2xl mt-10'>下單成功</p>
            <p className='text-main-black font-semibold my-4'>訂單編號:{supplier_order_id}</p>
            <p className='text-main-brown font-semibold'>付款須知</p>
            <p className='text-main-black text-base mb-4'>為確保您的訂單成立，請於48小時內完成匯款。</p>
            <div className='flex flex-col items-center mb-4'>
                <p className='text-main-brown font-semibold mb-2'>匯款方式</p>
                <p className='text-main-black font-medium'>請將款項匯至以下帳戶：</p>
                <p className='text-main-black'>戶名：正誠餐飲國際有限公司</p>
                <p className='text-main-black'>銀行：中國信託 822</p>
                <p className='text-main-black mb-2'>帳號：716540-258499</p>
                <p className='text-main-black font-medium text-[15px]'>匯款完成後</p>
                <p className='text-main-black font-medium text-[15px]'>請回傳以下資訊至帳房先生官方LINE：</p>
                <p className='text-main-black'>訂單編號：{supplier_order_id}</p>
                <p className='text-main-black'>匯款帳號末五碼：[末五碼]</p>
                <p className='text-main-black font-medium text-[15px] mt-2'>(官方line帳號：@373gznkg)</p>
            </div>
            <p className='text-main-brown font-semibold mb-2'>注意事項</p>
            <p className='text-main-black text-base'>可在匯款前至<span className='font-semibold'>後台管理 \ 批貨管理</span>編輯訂購數量。</p>
            <div className="bg-white w-full h-auto flex flex-col justify-center items-center fixed bottom-0 shadow-up">
                <Link to={`/product/${productId}`}>
                    <button className="my-4 w-[343px] h-[50px] font-semibold text-white bg-main-brown rounded-full hover:bg-deep-brown">返回 商品頁</button>
                </Link>
                <Link to='/wholesale'>
                    <button className="mb-4 w-[343px] h-[50px] font-semibold text-main-brown border-[3px] border-main-brown rounded-full hover:text-deep-brown hover:border-deep-brown">返回 批貨商品頁</button>
                </Link>
            </div>
        </div>
    )
}

export default WholesaleSuccessPage;