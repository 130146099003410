import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Html5Qrcode } from 'html5-qrcode';
import { motion } from 'framer-motion';
import {
  useSellerOrdersQuery,
  useSellerOrdersDetailQuery,
  useDeleteOrdersMutation,
  useSellerOrdersUpdateMutation,
  useGetCampaignsQuery,
} from '../store/slice/apiSlice';
import TruncatedText from '../components/TruncateText';
import left from '../images/left.svg';
import scan from '../images/scan.svg';
import cross from '../images/cross.svg';
import xmark from '../images/xmark.svg';
import caution from '../images/caution.svg';
import success from '../images/success.svg';
import Switch from '../components/Switch';
import emptybox from '../images/emptybox.svg';
import emptysearch from '../images/emptysearch.svg';

function FastDeliveryPage() {
  const { data: campaignsData } = useGetCampaignsQuery({
    page: 1,
    page_size: 100,
  });
  const [campaignId, setCampaignId] = useState('');

  useEffect(() => {
    console.log(campaignsData);
  }, [campaignsData, campaignId]);

  const handleCampaignChange = (event) => {
    const selectedCampaignId = event.target.value;
    setCampaignId(selectedCampaignId === '' ? '0' : selectedCampaignId);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    let pages = [];
    let startPage, endPage;

    if (maxPage <= 4) {
      startPage = 1;
      endPage = maxPage;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage + 2 >= maxPage) {
        startPage = maxPage - 3;
        endPage = maxPage;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const [orderBy, setOrderBy] = useState('desc');
  const [phone, setPhone] = useState('');
  const [search, setSearch] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const buyerId = parseInt(queryParams.get('buyer_id')) || 0;

  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setPhone(value);

    // Update the query based on input type
    if (value === '') {
      // If input is empty, reset search values
      setSearch({ campaign_name: '', buyer_phone: '' });
    } else if (!isNaN(value)) {
      // If input is a number, search by phone
      setSearch((prev) => ({ ...prev, buyer_phone: value }));
    } else {
      // If input is text, search by campaign name
      setSearch((prev) => ({ ...prev, campaign_name: value }));
    }
  };

  const { data, error } = useSellerOrdersQuery({
    order_status: '已到貨',
    campaign_id: campaignId === '' ? '0' : campaignId,
    campaign_name: search.campaign_name,
    buyer_phone: search.buyer_phone,
    buyer_id: buyerId,
    order_by: orderBy,
    page: currentPage,
    page_size: 6,
  });

  useEffect(() => {
    if (data && data.total_pages) {
      setMaxPage(data.total_pages || 1);
    } else {
      setMaxPage(1);
    }
    console.log(data);
  }, [data, maxPage]);

  const qrBoxId = 'html5qr-code';
  const html5QrCodeRef = useRef(null);
  const [isScanning, setIsScanning] = useState(false);
  const navigate = useNavigate();

  const startScanner = () => {
    if (isScanning) return;

    if (!html5QrCodeRef.current) {
      html5QrCodeRef.current = new Html5Qrcode(qrBoxId);
    }

    setIsScanning(true);

    const config = { fps: 10, qrbox: 250 };
    html5QrCodeRef.current
      .start({ facingMode: 'environment' }, config, (decodedText) => {
        console.log(`Decoded text: ${decodedText}`);
        navigate(decodedText);
        stopScanner();
      })
      .catch((err) => {
        console.error(`Error starting QR Scanner: ${err}`);
        setIsScanning(false);
      });
  };

  const stopScanner = () => {
    if (html5QrCodeRef.current) {
      html5QrCodeRef.current
        .stop()
        .then(() => {
          setIsScanning(false);
        })
        .catch((err) => {
          console.error(`Error stopping QR Scanner: ${err}`);
        });
    }
  };

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const orderDetail = useSellerOrdersDetailQuery(selectedOrderId, {
    skip: !selectedOrderId,
  });

  const handleOrderClick = (orderId) => {
    setSelectedOrderId(orderId);
  };
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  const [deleteOrder] = useDeleteOrdersMutation();
  const handleDeleteOrder = async () => {
    try {
      const result = await deleteOrder(selectedOrderId).unwrap();
      console.log(result);

      setShowDelete(false);
      setSelectedOrderId(null);
      setShowDeleteSuccess(true);

      setTimeout(() => {
        setShowDeleteSuccess(false);
      }, 1500);
    } catch (error) {
      console.error('Failed to delete order', error);
    }
  };

  const [isPaid, setIsPaid] = useState(false);
  const [isPickedUp, setIsPickedUp] = useState(false);

  useEffect(() => {
    if (orderDetail && orderDetail.data && orderDetail.data.data) {
      setIsPaid(orderDetail.data.data.is_paid);
      setIsPickedUp(orderDetail.data.data.is_picked_up);
    }
  }, [orderDetail]);

  const handlePaidToggle = () => {
    setIsPaid((current) => !current);
  };

  const handlePickedUpToggle = () => {
    setIsPickedUp((current) => !current);
  };

  const [productDetails, setProductDetails] = useState({});
  const [totals, setTotals] = useState({ totalItems: 0, totalAmount: 0 });

  useEffect(() => {
    if (
      orderDetail &&
      orderDetail.data &&
      orderDetail.data.data &&
      orderDetail.data.data.order_details
    ) {
      const initialDetails = {};
      let initialTotalItems = 0;
      let initialTotalAmount = 0;
      orderDetail.data.data.order_details.forEach((product) => {
        initialDetails[product.product_id] = {
          quantity: product.quantity,
          itemSubtotal: product.item_subtotal,
          totalItems: product.total_items,
          totalAmount: product.total_amount,
        };
        initialTotalItems += product.quantity;
        initialTotalAmount += product.item_subtotal;
      });
      setProductDetails(initialDetails);
      setTotals({
        totalItems: initialTotalItems,
        totalAmount: initialTotalAmount,
      });
    }
  }, [orderDetail]);

  const handleQuantityChange = (productId, newQuantity) => {
    const unitPrice = orderDetail.data.data.order_details.find(
      (product) => product.product_id === productId
    ).unit_price;
    const newItemSubtotal = newQuantity * unitPrice;

    setProductDetails((current) => {
      const newDetails = { ...current };
      newDetails[productId].quantity = newQuantity;
      newDetails[productId].itemSubtotal = newItemSubtotal;

      const newTotalItems = Object.values(newDetails).reduce(
        (sum, detail) => sum + detail.quantity,
        0
      );
      const newTotalAmount = Object.values(newDetails).reduce(
        (sum, detail) => sum + detail.itemSubtotal,
        0
      );

      setTotals({ totalItems: newTotalItems, totalAmount: newTotalAmount });
      return newDetails;
    });
  };

  const [updateOrder] = useSellerOrdersUpdateMutation();
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const prepareUpdatedOrderDetails = () => {
    return orderDetail.data.data.order_details.map((product) => ({
      product_id: product.product_id,
      quantity: productDetails[product.product_id]?.quantity || 0,
    }));
  };

  const handleUpdateOrder = async () => {
    const updatedOrder = {
      order_id: orderDetail.data.data.id,
      is_paid: isPaid,
      is_picked_up: isPickedUp,
      updated_order_details: prepareUpdatedOrderDetails(),
    };

    try {
      const response = await updateOrder(updatedOrder).unwrap();
      console.log(response);

      setSelectedOrderId(null);
      setUpdateSuccess(true);

      setTimeout(() => {
        setUpdateSuccess(false);
      }, 1500);
    } catch (error) {
      console.error('Failed to update order', error);
    }
  };

  return (
    <div className="bg-[#f5f5f5] w-full h-screen flex flex-col items-center">
      <div className="bg-white w-full h-[72px] flex justify-between items-center relative">
        <Link to="/seller">
          <img alt="left" src={left} className="pl-4" />
        </Link>
        <p className="text-xl font-semibold text-main-black">快速交貨</p>
        <div
          className="w-[50px] pr-4 cursor-pointer hover:opacity-80"
          onClick={startScanner}
        >
          <img alt="scan" src={scan} />
        </div>
      </div>
      <div
        className={`relative ${isScanning ? '' : 'hidden'}`}
        style={{ width: '100%', height: '100%' }}
      >
        <div id={qrBoxId} className="absolute inset-0 z-10"></div>
        {isScanning && (
          <img
            alt="cross"
            src={cross}
            className="absolute top-2 right-2 w-6 h-6 z-20 cursor-pointer"
            onClick={stopScanner}
          />
        )}
      </div>
      {isScanning ? (
        ''
      ) : (
        <>
          <motion.div
            className="flex flex-col p-4 items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <select
              className="text-main-black w-[343px] h-[44px] rounded-full px-3 my-4 border-[1px] border-[#ebd9c9] appearance-none arrowdown
                        focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]"
              onChange={handleCampaignChange}
              value={campaignId}
            >
              <option value="">全部的團購</option>
              {campaignsData &&
                campaignsData.data.map((campaign, index) => (
                  <option key={index} value={campaign.campaign_id}>
                    {campaign.name}
                  </option>
                ))}
            </select>
            <div className="flex mb-4">
              <input
                placeholder="名稱 手機末四碼"
                className="w-[163.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black mr-4 search
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]"
                value={phone}
                onChange={handleSearchChange}
              />
              <select
                className="w-[163.5px] h-[44px] rounded-full px-3 border-[1px] border-[#ebd9c9] text-main-black appearance-none arrowdown
                            focus:outline-none focus:border-[3px] focus:border-[#f2ca7f]"
                value={orderBy}
                onChange={handleOrderChange}
              >
                <option value="desc">排序：最新</option>
                <option value="asc">排序：最舊</option>
              </select>
            </div>
            {error && <div className="mt-10">此功能需登入方可使用</div>}
            {data && data.data.length === 0 ? ( // Check if there are no orders
              <div className="flex flex-col items-center min-h-[500px] justify-center">
                {search.campaign_name || search.buyer_phone ? ( //
                  <>
                    <img alt="empty" src={emptysearch} />
                    <p className="text-[#858585] mt-4">
                      抱歉，未找到符合條件的結果
                    </p>
                  </>
                ) : (
                  <>
                    <img alt="empty" src={emptybox} />
                    <p className="text-[#858585] mt-4">目前無待交貨訂單</p>
                  </>
                )}
              </div>
            ) : (
              data &&
              data.data.map(
                (
                  item,
                  index // Existing code to display orders
                ) => (
                  <motion.div
                    key={index}
                    className="w-[343px] h-[60px] bg-white rounded-lg flex items-center p-2 mb-4 justify-between shadow-md cursor-pointer"
                    onClick={() => handleOrderClick(item.order_id)}
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}
                  >
                    <p className="text-main-black w-[100px] ml-2">
                      <TruncatedText text={item.buyer_name} maxLength={9} />
                    </p>
                    <p className="text-main-black w-[67px]">{item.order_id}</p>
                    <p className="text-[#858585] w-[160px] mr-2">
                      <TruncatedText text={item.campaign_name} maxLength={20} />
                    </p>
                  </motion.div>
                )
              )
            )}
          </motion.div>
          {selectedOrderId && orderDetail.data && (
            <div
              className="fixed inset-0 bg-black/60 flex justify-center items-center z-10"
              onClick={() => setSelectedOrderId(null)}
            >
              <div
                className="bg-white p-4 rounded-lg w-[343px] h-auto relative"
                onClick={(event) => event.stopPropagation()}
              >
                <img
                  alt="close"
                  src={xmark}
                  className="absolute top-4 right-4 h-6 w-6 cursor-pointer"
                  onClick={() => setSelectedOrderId(null)}
                />
                <p className="text-main-black text-lg font-semibold mb-4">
                  訂單詳情
                </p>
                <img
                  alt="product"
                  src={orderDetail.data.data.order_details[0].product_image}
                  className="w-[110px] h-[110px] rounded-lg mb-4"
                />
                <div className="flex justify-between">
                  <p className="text-main-black text-base font-semibold mb-4">
                    {orderDetail.data.data.campaign_name}
                  </p>
                  <p className="text-main-black text-base font-semibold mb-4">
                    {orderDetail.data.data.id}
                  </p>
                </div>
                {orderDetail.data.data.order_details.map((product, index) => (
                  <div key={index} className="flex justify-between mb-4">
                    <p className="text-main-black text-base w-[150px]">
                      {product.product_name}
                    </p>
                    <input
                      className="text-main-black text-base w-12 text-center border-2 border-[#ebd9c9] rounded-full
                                            focus:outline-none focus:border-[#f2ca7f]"
                      value={productDetails[product.product_id]?.quantity || ''}
                      onChange={(e) =>
                        handleQuantityChange(
                          product.product_id,
                          parseInt(e.target.value, 10) || 0
                        )
                      }
                    ></input>
                    <p className="text-main-black text-base w-[100px] text-end">
                      $
                      {Math.round(
                        productDetails[product.product_id]?.itemSubtotal
                      ) || '0'}
                    </p>
                  </div>
                ))}
                <div className="flex justify-between border-y-[1px] pt-2 pb-6">
                  <p className="text-main-black text-base w-[150px]">總額</p>
                  <p className="text-main-black text-base w-12 text-end">
                    {totals.totalItems}
                  </p>
                  <p className="text-[#e34a4f] font-semibold text-base w-[100px] text-end">
                    ${Math.round(totals.totalAmount)}
                  </p>
                </div>
                <div className="flex justify-between mt-6">
                  <p className="text-main-black text-base font-medium mb-3">
                    購買者
                  </p>
                  <p className="text-main-black">
                    {orderDetail.data.data.buyer_name}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-main-black text-base font-medium mb-3">
                    電話 末四碼
                  </p>
                  <p className="text-main-black">
                    {orderDetail.data.data.phone}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-main-black text-base font-medium mb-3">
                    取貨點
                  </p>
                  <p className="text-main-black">
                    {orderDetail.data.data.pickup}
                  </p>
                </div>
                <div className="flex justify-end">
                  <p className="text-main-brown mb-3">#常客 #熱愛糕點</p>
                </div>
                <div className="flex justify-end items-center mb-3">
                  <p className="text-main-black mr-2">未付款</p>
                  <Switch isToggled={isPaid} toggleSwitch={handlePaidToggle} />
                  <p className="text-main-black ml-2">已付款</p>
                </div>
                <div className="flex justify-end items-center mb-6">
                  <p className="text-main-black mr-2">未取貨</p>
                  <Switch
                    isToggled={isPickedUp}
                    toggleSwitch={handlePickedUpToggle}
                  />
                  <p className="text-main-black ml-2">已取貨</p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[55px] rounded-full hover:text-deep-brown hover:border-deep-brown"
                    onClick={() => setShowDelete(true)}
                  >
                    取消訂單
                  </button>
                  <button
                    className="text-white font-semibold bg-main-brown w-[190px] h-[55px] rounded-full ml-4 hover:bg-deep-brown"
                    onClick={handleUpdateOrder}
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          )}
          {showDelete && (
            <div
              className="fixed inset-0 bg-black/60 flex justify-center items-center z-10"
              onClick={() => setShowDelete(false)}
            >
              <div
                className="bg-white p-6 rounded-lg w-[300px] h-auto relative"
                onClick={(event) => event.stopPropagation()}
              >
                <img
                  alt="close"
                  src={xmark}
                  className="absolute top-4 right-4 h-6 w-6 cursor-pointer"
                  onClick={() => setShowDelete(false)}
                />
                <img alt="caution" src={caution} className="h-10 w-10 mb-6" />
                <p className="text-main-black text-lg font-semibold mb-3">
                  注意
                </p>
                <p className="text-main-black text-base mb-8">
                  點擊取消訂單，此訂單將更改為
                  <span className="text-main-brown">不成立</span>
                  狀態，您確定要執行此操作嗎？
                </p>
                <div className="flex justify-center">
                  <button
                    className="text-main-brown font-semibold border-[3px] border-main-brown w-[104px] h-[48px] rounded-full hover:text-deep-brown hover:border-deep-brown"
                    onClick={() => setShowDelete(false)}
                  >
                    取消
                  </button>
                  <button
                    className="text-white font-semibold bg-main-brown w-[190px] h-[48px] rounded-full ml-2 hover:bg-deep-brown"
                    onClick={handleDeleteOrder}
                  >
                    確定取消訂單
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteSuccess && (
        <div className="z-10 fixed top-[72px] w-full bg-[#e6f4ff] flex justify-center items-center">
          <img alt="success" src={success} className="py-4" />
          <p className="text-[#45a5de] font-semibold text-2xl pl-2">取消訂單</p>
        </div>
      )}
      {updateSuccess && (
        <div className="z-10 fixed top-[72px] w-full bg-[#e6f4ff] flex justify-center items-center">
          <img alt="success" src={success} className="py-4" />
          <p className="text-[#45a5de] font-semibold text-2xl pl-2">修改完成</p>
        </div>
      )}
      <div className="flex justify-end mt-10 mb-20 pb-20">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center mr-3 cursor-pointer"
        >
          &lt;
        </button>
        {currentPage > 2 && maxPage > 5 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className="font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3"
            >
              1
            </button>
            {currentPage > 3 && <span className="mr-3">...</span>}
          </>
        )}
        {getPageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`font-poppins w-8 h-8 rounded-full ${currentPage === page ? 'bg-[#f2ca7f] text-white' : 'bg-white text-main-black'} flex justify-center items-center mr-3`}
          >
            {page}
          </button>
        ))}
        {currentPage < maxPage - 2 && maxPage >= 6 && (
          <>
            {currentPage < maxPage - 2 && <span className="mr-3">...</span>}
            <button
              onClick={() => handlePageChange(maxPage)}
              className="font-poppins w-8 h-8 rounded-full bg-white text-main-black flex justify-center items-center mr-3"
            >
              {maxPage}
            </button>
          </>
        )}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === maxPage}
          className="font-poppins w-8 h-8 rounded-full bg-white text-main-black text-2xl flex justify-center items-center cursor-pointer"
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

export default FastDeliveryPage;
