import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import left from '../images/left.svg';
import store from '../images/build a store.svg';
import deiveryman from '../images/delivery man.svg';
import qrcode from '../images/qr code.svg';
import manage from '../images/manage.svg';

function SellerPage () {
    return (
        <div className="bg-[#f5f5f5] w-full max-h-max min-h-screen flex flex-col items-center pb-8">
            <div className="bg-white w-full h-[72px] flex justify-between items-center">
                <Link to='/home'>
                    <img alt='left' src={left} className='pl-4' />
                </Link>
                <p className="text-xl font-semibold text-main-black">團購主</p>
                <div className='w-[50px] pr-4'></div>
            </div>
            <motion.div className='flex flex-col p-4 items-center'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}>
                <p className='text-main-black font-semibold text-lg mb-4'>您今天要使用什麼功能？</p>
                <Link to='/store'>
                    <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}>
                        <img alt='store' src={store} className='mr-3'/>我要開團</motion.button>
                </Link>
                <Link to='/fastdelivery'>
                    <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}>
                        <img alt='deliverman' src={deiveryman} className='mr-3'/>快速交貨</motion.button>
                </Link>
                <Link to='/groupbuying'>
                    <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md mb-4'
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}>
                        <img alt='qrcode' src={qrcode} className='mr-3'/>團購連結</motion.button>
                </Link>
                <Link to='/managecampaign'>
                    <motion.button className='flex text-2xl text-main-brown font-semibold items-center justify-center bg-white w-[343px] h-[120px] rounded-xl shadow-md'
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4 }}>
                        <img alt='manage' src={manage} className='mr-3'/>後台管理</motion.button>
                </Link>
            </motion.div>
        </div>
    )
}

export default SellerPage;