function TruncateText({text, maxLength}) {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    return <span>{truncateText(text, maxLength)}</span>;
}

export default TruncateText;